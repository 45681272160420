<div class="card mat-elevation-z4">
  <div class="card-header">
    <div class="route-status">
      <div
        class="round-icon-box mat-elevation-z3"
        [matTooltip]="routing.is_active ? 'Active' : 'Paused'"
      >
        <mat-icon
          svgIcon="custom-routing-{{ routing.is_active ? 'active' : 'paused' }}"
          class="blue-icon"
        ></mat-icon>
      </div>
    </div>

    <h3 class="card-title">{{ routing.name }}</h3>
  </div>
  <div class="card-body">
    <div class="round-icon-box mat-elevation-z3">
      <mat-icon svgIcon="custom-infos" class="blue-icon"></mat-icon>
    </div>
    <p class="pattern" [title]="routing.pattern">{{ routing.pattern }}</p>
    <span style="flex: 1 1 auto"></span>
    <app-counter-chip [label]="routing.destination_type"></app-counter-chip>
  </div>
  <div class="card-footer">
    <div class="data">
      <app-counter-chip
        [label]="'Devices'"
        [count]="routing.devices_number"
      ></app-counter-chip>
      <app-counter-chip
        [label]="'Data'"
        [count]="routing.data_number"
      ></app-counter-chip>
      <app-counter-chip
        *ngIf="routing.destination_name"
        [label]="routing.destination_name"
      ></app-counter-chip>
    </div>

    <button mat-button class="open-btn" (click)="openDetails(routing.id)">
      open
    </button>
  </div>
</div>
