import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { SiteTag } from 'src/models/device.models';
import { User } from 'src/models/user.models';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
})
export class UserFilterComponent implements OnInit {
  @Input() users: User[];
  @Input() sites: SiteTag[];
  @Output() usersFiltered = new EventEmitter<User[]>();
  permissionLevels = [
    { name: 'Operator', level: 0 },
    { name: 'Maintainer', level: 1 },
    { name: 'Administrator', level: 2 },
    { name: 'Technical Administrator', level: 3 },
  ];
  form: UntypedFormGroup;

  constructor(private data: DataStoreService) {}

  ngOnInit(): void {
    this.form = this.buildFilterForm();

    merge(
      this.form.valueChanges,
      this.data.users$.pipe(
        tap((users) => {
          this.users = users ? users : [];
        }),
      ),
    )
      .pipe(
        startWith(this.form.value),
        tap(() => {
          this.emitFilteredUsers();
        }),
      )
      .subscribe();
  }

  private emitFilteredUsers() {
    const siteKeyword = this.form.value.site;
    const level = this.form.value.permissionLevel;
    let result = this.users;

    if (level !== 4 && siteKeyword) {
      result = this.permissionForSiteFilter(result);
      result = this.emailFilter(result);
    } else {
      result = this.siteFilter(result);
      result = this.permissionLevelFilter(result);
      result = this.emailFilter(result);
    }

    this.usersFiltered.emit(result);
  }

  private permissionLevelFilter(users: User[]): User[] {
    const level = this.form.value.permissionLevel;
    return level === 4
      ? users
      : users.filter((user) => {
          switch (level) {
            case 3:
              return user.technical_administrator;
            case 2:
              return user.administrator;
            case 1:
              return Object.values(user.roles).includes(level);
            case 0:
              return Object.values(user.roles).includes(level);
            default:
              return false;
          }
        });
  }

  private permissionForSiteFilter(users: User[]): User[] {
    const siteKeyword = this.form.value.site;
    const level = this.form.value.permissionLevel;

    return users.filter((user) =>
      {
        switch (level) {
          case 3:
            return user.technical_administrator;
          case 2:
            return user.administrator;
          case 1:
          case 0:
            return user.roles[siteKeyword] === level
        }
      }
    );
  }

  private siteFilter(users: User[]): User[] {
    const siteKeyword = this.form.value.site;
    return !siteKeyword
      ? users
      : users.filter(
          (user) =>
            Object.keys(user.roles).filter((site) => site === siteKeyword)
              .length || user.administrator,
        );
  }

  private emailFilter(users: User[]): User[] {
    const keyword = this.form.value.email.toLowerCase();
    return !keyword
      ? users
      : users.filter((user) => user.user_email.toLowerCase().includes(keyword));
  }

  private buildFilterForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(''),
      permissionLevel: new UntypedFormControl(4),
      site: new UntypedFormControl(null),
    });
  }
}
