<h1 mat-dialog-title *ngIf="data.id === '_switch'">
  Edit the conditions for parser selection
</h1>
<h1 mat-dialog-title *ngIf="data.id === '_default'">Edit the default parser</h1>
<h1 mat-dialog-title *ngIf="data.id !== '_switch' && data.id !== '_default'">
  Edit the single parser: {{ data.id }}
</h1>
<div mat-dialog-content>
  <app-single-parser-editor
    [config_id]="data.id"
    [config]="data.config"
    (configModified)="configModified($event)"
  ></app-single-parser-editor>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    [disabled]="!configValid"
  >
    Confirm
  </button>
</div>
