import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/app/services/shared.module';
import { UnauthorizedComponent } from './unauthorized.component';

const COMPONENTS = [UnauthorizedComponent];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class UnauthorizedModule {}
