import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-data-select-list',
  templateUrl: './data-select-list.component.html',
  styleUrls: ['./data-select-list.component.scss'],
})
export class DataSelectListComponent {
  @Input() pattern: string;
  @Input() data: string[];
  @Output() dataSelected = new EventEmitter<string>();

  constructor() {}

  public dataClicked(data: string): void {
    this.dataSelected.emit(data);
  }
}
