import { Component, Input } from '@angular/core';
import { DeviceTypeDto } from 'src/models/device-type.models';

@Component({
  selector: 'app-device-type-banner',
  templateUrl: './device-type-banner.component.html',
  styleUrls: ['./device-type-banner.component.scss'],
})
export class DeviceTypeBannerComponent {
  @Input() deviceType: DeviceTypeDto;
  constructor() {}
}
