<div mat-dialog-content>
  <h2>Update device picture</h2>

  <div [formGroup]="pictureForm">
    <input
      type="file"
      name="{{ id }}"
      id="{{ id }}"
      accept="image/*"
      class="inputfile"
      (change)="selectionChanged($event)"
      formControlName="image_file"
    />
    <label for="{{ id }}" class="mat-elevation-z3">
      <img [src]="deviceImage" alt="Preview" class="device-image" />
    </label>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-button
    (click)="onSubmit()"
    [disabled]="!pictureForm.valid"
    class="submit"
  >
    Update
  </button>
</div>
