import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import {
  filter,
  first,
  map,
  skip,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DeviceHierarchyStoreService } from 'src/app/services/state/data/device-hierarchy/device-hierarchy-store.service';
import { HierarchyNode } from '../../../../models/device-hierarchy.models';
import { DeviceManagementService } from './device-management.service';

@Component({
  selector: 'app-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss'],
})
export class DeviceManagementComponent implements OnInit {
  public selectedNode$ = this.deviceManagement.selectedNode$;

  searchControl = this.hierarchyStore.searchControl;

  options$ = this.hierarchyStore.searchOptions$;

  loaded$ = this.hierarchyStore.deviceHierarchyTree$;

  constructor(
    private route: ActivatedRoute,
    private hierarchyStore: DeviceHierarchyStoreService,
    private deviceManagement: DeviceManagementService,
  ) {
    this._loadData();
  }

  hierarchyLoaded(hierarchy: HierarchyNode[]) {
    return !!hierarchy?.length;
  }

  private _loadData() {
    this.hierarchyStore.loadDeviceHierarchy().pipe(first()).subscribe();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        map((params) => params.path ?? ''),
        tap((path) => this.deviceManagement.setPath(path)),
        filter((path) => !!path.length),
        switchMap((path) => this.updateSelectedNode(path)),
      )
      .subscribe();
  }

  resetSearch() {
    const path = this.route.snapshot.queryParams.path;
    this.hierarchyStore.filteredHierarchyTree$
      .pipe(skip(1), first())
      .subscribe((hierarchy) => {
        this.deviceManagement.setSelectedNode(
          this.hierarchyStore.getSelectedNodeForPath(path, hierarchy),
        );
      });
    this.searchControl.setValue('');
  }

  private updateSelectedNode(path: string) {
    return of(path).pipe(
      switchMap(() =>
        this.hierarchyStore.filteredHierarchyTree$.pipe(
          skipWhile((v) => !v.length),
          first(),
        ),
      ),
      tap((hierarchy) => {
        this.deviceManagement.setSelectedNode(
          this.hierarchyStore.getSelectedNodeForPath(path, hierarchy),
        );
      }),
    );
  }
}
