import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from './../../../../../services/state/auth/auth.service';
import { DeviceIdValidator } from './../../device-exists.validator';

import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceTypeDto } from 'src/models/device-type.models';
import { DefaultDeviceInfo, SiteTag } from 'src/models/device.models';
import { DeviceManagementService } from '../../device-management.service';
import { DeviceCreation } from '../abstract-device-creation';
import { defaultCreationSteps } from '../device-creation.config';
import { DeviceCreationService } from '../device-creation.service';

@Component({
  selector: 'app-default-device-creation',
  templateUrl: './default-device-creation.component.html',
  styleUrls: ['./default-device-creation.component.scss'],
})
export class DefaultDeviceCreationComponent
  extends DeviceCreation
  implements OnInit
{
  // interface
  activeStepConfig$ = this.deviceCreation.activeStep$;
  activeStepIndex: number;

  // interface data
  deviceClasses = this.data.deviceClasses;
  deviceType = this.deviceCreation.selectedDeviceType as DeviceTypeDto;
  site$: Observable<SiteTag | undefined>;
  userAccessFlags = this.authService.claims?.accessFlags || [];

  // data collection
  form = this.buildForm();
  deviceControlGroups = new Set<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private data: DataStoreService,
    private deviceCreation: DeviceCreationService,
    public deviceManagement: DeviceManagementService,
    public authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.stepQueryParamsHandler$.subscribe();
    this.pathQueryParamHandler$.subscribe();
  }

  private get stepQueryParamsHandler$(): Observable<number> {
    return this.route.queryParams.pipe(
      map((params) => parseInt(params.step, 10)),
      tap((step) => (this.activeStepIndex = step)),
      tap((step) =>
        this.deviceCreation.setActiveStep(step, defaultCreationSteps),
      ),
      filter((step) => !this.stepValidator(step - 1)),
      tap(() =>
        this.router.navigate([], {
          queryParams: { step: 1 },
          queryParamsHandling: 'merge',
        }),
      ),
    );
  }

  private get pathQueryParamHandler$(): Observable<string> {
    return this.route.queryParams.pipe(
      map((params) => params.path ?? ''),
      tap((path) => {
        this.deviceManagement.setPath(path);
        this.site$ = this.data.findSite(this.deviceManagement.activeProject);
      }),
    );
  }

  public stepValidator(step = this.activeStepIndex): boolean {
    const general = this.generalForm.valid;
    const position = this.positionForm.valid;
    const metadata = this.metadataForm.valid;

    switch (step) {
      case 1:
        return general;
      case 2:
        return position && general;
      default:
        return metadata && position && general;
    }
  }

  public submitForm(): void {
    const source = this.deviceType?.source.source_id;
    const deviceInfo: DefaultDeviceInfo = {
      ...this.generalForm.value,
      attributeTags: Array.from(this.deviceControlGroups),
      projectIds: [this.deviceManagement.activeProject],
      metadata: {
        ...this.positionForm.value,
        ...this.metadataForm.value,
        description: this.descriptionFormControl.value,
      },
    };
    this.deviceCreation.createDevice(deviceInfo, source);
  }

  public onFlagChange(checked: boolean, flag: string): void {
    checked
      ? this.deviceControlGroups.add(flag)
      : this.deviceControlGroups.delete(flag);
  }

  private buildForm(): UntypedFormGroup {
    const form = new UntypedFormGroup({
      general: new UntypedFormGroup({
        name: new UntypedFormControl(null, Validators.required),
        hierarchyPrefix: new UntypedFormControl(
          this.deviceManagement.hierarchyPrefix,
          Validators.required,
        ),
        deviceTypeId: new UntypedFormControl(
          this.deviceType?.device_type_id,
          Validators.required,
        ),
        deviceId: new UntypedFormControl(
          null,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.pattern(/^[A-Za-z0-9][A-Za-z0-9._-]{0,68}[A-Za-z0-9]$/),
          ],
          [DeviceIdValidator.createValidator(this.deviceManagement)],
        ),
      }),
      position: new UntypedFormGroup({
        lat: new UntypedFormControl(null, Validators.required),
        lng: new UntypedFormControl(null, Validators.required),
      }),
      metaData: new UntypedFormGroup({}),
      description: new UntypedFormControl(''),
    });
    return form;
  }
}
