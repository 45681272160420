import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InterfaceCreationComponent } from 'src/app/pages/main/interface-management/interface-creation/interface-creation.component';
import { InterfaceFilterComponent } from 'src/app/pages/main/interface-management/interface-filter/interface-filter.component';
import { SharedModule } from 'src/app/services/shared.module';
import { InterfaceManagementComponent } from './interface-management.component';

@NgModule({
  declarations: [
    InterfaceManagementComponent,
    InterfaceFilterComponent,
    InterfaceCreationComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
})
export class InterfaceManagementModule {}
