<table
  class="mat-elevation-z4"
  mat-table
  matSort
  multiTemplateDataRows
  [dataSource]="dataSource"
  aria-describedby="list of devices"
>
  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      disableClear="true"
      id="name"
    >
      Name
    </th>
    <td mat-cell *matCellDef="let device" class="clip-text">
      {{ device?.metadata?.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef id="status">Status</th>
    <td mat-cell *matCellDef="let device">
      <mat-icon
        class="red"
        *ngIf="
          device?.statistics?.low_battery &&
          device?.statistics?.last_uplink_error &&
          device?.statistics?.last_uplink
        "
        >fiber_manual_record</mat-icon
      >
      <mat-icon
        class="green"
        *ngIf="
          !device?.statistics?.low_battery &&
          !device?.statistics?.last_uplink_error &&
          device?.statistics?.last_uplink
        "
        >fiber_manual_record</mat-icon
      >
      <mat-icon class="grey" *ngIf="!device?.statistics?.last_uplink"
        >fiber_manual_record</mat-icon
      >
      <mat-icon
        class="orange"
        *ngIf="
          device?.statistics?.last_uplink_error &&
          !device?.statistics?.low_battery &&
          device?.statistics?.last_uplink
        "
        >fiber_manual_record</mat-icon
      >
      <mat-icon
        class="yellow"
        *ngIf="
          device?.statistics?.low_battery &&
          !device?.statistics?.last_uplink_error &&
          device?.statistics?.last_uplink
        "
        >fiber_manual_record</mat-icon
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="site">
    <th
      mat-header-cell
      mat-sort-header
      disableClear="true"
      *matHeaderCellDef
      [hidden]="(screensizeIndex$ | async) <= 1"
      id="site"
    >
      Site
    </th>
    <td
      mat-cell
      *matCellDef="let device"
      [hidden]="(screensizeIndex$ | async) <= 1"
    >
      {{ siteLongName(device) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="last_uplink">
    <th
      mat-header-cell
      mat-sort-header
      disableClear="true"
      *matHeaderCellDef
      [hidden]="(screensizeIndex$ | async) <= 0"
      id="uplink"
    >
      Last Uplink
    </th>
    <td
      mat-cell
      *matCellDef="let device"
      [hidden]="(screensizeIndex$ | async) <= 0"
    >
      <span
        *ngIf="device?.statistics?.last_uplink"
        [ngClass]="{ red: device?.statistics?.last_uplink_error }"
      >
        {{ device?.statistics?.last_uplink | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ' }}
      </span>
      <span *ngIf="!device?.statistics?.last_uplink"> Unknown </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="battery">
    <th
      mat-header-cell
      *matHeaderCellDef
      [hidden]="(screensizeIndex$ | async) <= 0"
      id="battery"
    >
      Battery
    </th>
    <td
      mat-cell
      *matCellDef="let device"
      [hidden]="(screensizeIndex$ | async) <= 0"
    >
      <ng-container
        *ngIf="
          device?.statistics?.battery_powered === false;
          else batteryTemplate
        "
      >
        <mat-icon class="green">power</mat-icon>
      </ng-container>
      <ng-template #batteryTemplate>
        <mat-icon
          class="green"
          *ngIf="
            device?.statistics['battery/0/percentage'] ||
              device?.statistics['battery/0/voltage'];
            else noPercentTpl
          "
          matTooltipPosition="before"
          [matTooltip]="
            (device?.statistics['battery/0/percentage']
              ? device?.statistics['battery/0/percentage'] + ' %   '
              : '') +
            (device?.statistics['battery/0/voltage']
              ? device?.statistics['battery/0/voltage'] + ' V'
              : '')
          "
        >
          battery_saver
        </mat-icon>

        <ng-template #noPercentTpl>
          <mat-icon
            *ngIf="
              device?.statistics?.low_battery === true ||
              device?.statistics?.low_battery === 1
            "
            class="red"
          >
            battery_alert
          </mat-icon>
          <mat-icon
            *ngIf="
              device?.statistics?.low_battery === false ||
              device?.statistics?.low_battery === 0
            "
            class="green"
          >
            battery_full
          </mat-icon>
          <mat-icon *ngIf="device?.statistics?.low_battery === null">
            battery_unknown
          </mat-icon>
        </ng-template>
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="routings">
    <th mat-header-cell *matHeaderCellDef id="routings">Routings</th>
    <td mat-cell *matCellDef="let device">
      <button
        mat-icon-button
        (click)="onShowRoutings(device)"
        matTooltipPosition="before"
        matTooltip="show routings which include this device"
      >
        <mat-icon svgIcon="custom-route-btn" class="info-icon"></mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="info">
    <th mat-header-cell *matHeaderCellDef id="info" class="info-column">
      Info
    </th>
    <td mat-cell *matCellDef="let device" class="info-column">
      <button
        mat-icon-button
        (click)="onShowDetails(device)"
        matTooltipPosition="before"
        matTooltip="show device details"
      >
        <mat-icon svgIcon="custom-info" class="info-icon"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
    class="header-row"
  ></tr>
  <tr
    mat-row
    *matRowDef="let device; columns: displayedColumns"
    class="device-row"
  ></tr>
</table>
