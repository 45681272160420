import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DevicesService } from 'src/app/services/http/devices.service';
import { WizardStep } from 'src/models/app-routing.models';

import { routingCreationSteps } from './routing-creation.config';

@Injectable()
export class RoutingCreationService {
  private _activeStep$: BehaviorSubject<WizardStep>;
  private _routeData$ = new BehaviorSubject<string[]>([] as string[]);

  constructor(private deviceHttp: DevicesService) {
    this._activeStep$ = new BehaviorSubject({
      ...routingCreationSteps[0],
      step: 1,
      totalSteps: routingCreationSteps.length,
    });
  }

  public get activeStep$(): Observable<WizardStep> {
    return this._activeStep$.asObservable();
  }

  public setActiveStep(step: number): void {
    const nextStep: WizardStep = {
      ...routingCreationSteps[step - 1],
      step,
      totalSteps: routingCreationSteps.length,
    };
    this._activeStep$.next(nextStep);
  }

  public get routeData$(): Observable<string[]> {
    return this._routeData$.asObservable();
  }

  public updateRouteData(pattern: string) {
    this.deviceHttp
      .getPatternData(pattern)
      .pipe(
        tap((data) => {
          this._routeData$.next(data);
        }),
      )
      .subscribe();
  }
}
