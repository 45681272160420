import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { UserClaims } from 'src/models/device-hierarchy.models';

const PROJECT_TAG_PREFIX = 'site_';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _claims: UserClaims | undefined;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private data: DataStoreService,
  ) {}

  public login() {
    this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => this.router.navigateByUrl('/home'));
  }
  public logout() {
    this.afAuth.signOut();
    this.data.resetStore();
  }

  public isTechnicalAdmin() {
    return this._claims ? this._claims.technical_admin : false;
  }

  public isAdmin() {
    return this._claims ? this.isTechnicalAdmin() || this._claims.admin : false;
  }

  public isMaintainer() {
    return this._claims
      ? this.isAdmin() || this._claims.maintainer.length
      : false;
  }

  public isMaintainerOnSite(site: string): boolean {
    return this._claims
      ? this.isAdmin() || this._claims.maintainer.includes(site)
      : false;
  }

  private parseRouteIdParam(id: string) {
    const chunks = id.split('#');
    return {
      routeCollectionId: chunks[0],
      interfaceDocId: chunks[1],
      siteId: chunks[2],
    };
  }

  public isMaintainerOnRoute(routeId: string): boolean {
    const { siteId } = this.parseRouteIdParam(routeId);
    return this.isMaintainerOnSite(siteId);
  }

  public hasWriteRightsForProject(path: string): boolean {
    return (
      this.isAdmin() ||
      !!this._claims!.maintainer.find((proj) => path.indexOf(proj) > -1)
    );
  }

  public get userHasMultipleSiteAccess(): boolean {
    const sitesAccessible = [
      ...(this.claims ? this.claims.maintainer! : []),
      ...(this.claims ? this.claims.operator! : []),
    ];
    const siteCount = this.claims?.admin ? 2 : sitesAccessible.length;
    return siteCount > 1;
  }

  public get accessibleSites(): string[] {
    return [
      ...(this.claims ? this.claims.maintainer! : []),
      ...(this.claims ? this.claims.operator! : []),
    ];
  }

  public isLoggedIn$(): Observable<boolean> {
    return this.afAuth.user.pipe(
      filter((user) => user !== null),
      map((user) => !!user),
    );
  }

  public isAuthorized$(): Observable<boolean> {
    return this.afAuth.idTokenResult.pipe(
      switchMap((tokenRes) =>
        // using throw and catchError to skip to the end of the Observable chain and return an Observable of false in case the user is not logged in
        tokenRes === null ? throwError(false) : of(tokenRes),
      ),
      // tslint:disable-next-line: no-non-null-assertion
      map((tokenRes) => this.mapClaims(tokenRes!.claims)),
      tap((claims) => (this._claims = claims)),
      map(
        (claims) =>
          claims.admin ||
          claims.maintainer.length > 0 ||
          claims.operator.length > 0,
      ),
      catchError((bool) => of(bool)),
    );
  }

  public getToken$(): Observable<string> {
    return this.afAuth.authState.pipe(
      filter((user) => !!user),
      // tslint:disable-next-line: no-non-null-assertion
      switchMap((user) => from(user!.getIdToken())),
    );
  }

  private mapClaims(claims: Record<string, unknown>): UserClaims {
    const res = {
      admin: !!claims.admin,
      technical_admin: !!claims.technical_admin,
      operator: [] as string[],
      maintainer: [] as string[],
      accessFlags: claims.accessFlags as string[],
    };

    const keys = Object.keys(claims);
    keys
      .filter((key) => key.startsWith(PROJECT_TAG_PREFIX))
      .forEach((key) => {
        if (claims[key] === 0) {
          res.operator.push(key);
        } else if (claims[key] === 1) {
          res.maintainer.push(key);
        }
      });

    return res;
  }

  public get claims(): UserClaims | undefined {
    return this._claims;
  }

  public get userEmail$(): Observable<string | undefined> {
    return this.afAuth.authState.pipe(map((user) => user?.email ?? undefined));
  }
}
