<ng-container *appLoader="allDevices$">
  <div class="header mat-elevation-z4">
    <h3 class="title">Elements</h3>
    <div class="spacer"></div>
    <button
      mat-flat-button
      color="primary"
      *ngIf="hasDragData"
      (click)="resetDragData()"
    >
      Uncheck all devices
    </button>
    <button
      mat-flat-button
      *ngIf="auth.isTechnicalAdmin() || auth.isAdmin()"
      (click)="openMultiDownlink()"
      class="view-toggle-left view-toggle"
      [class.active]="router.url.includes('multi-downlink')"
    >
      <mat-icon>list</mat-icon>
    </button>
    <button
      mat-flat-button
      *ngIf="auth.isTechnicalAdmin() || auth.isAdmin()"
      (click)="openManager()"
      class="view-toggle view-toggle-right"
      [class.active]="router.url.includes('manager')"
    >
      <mat-icon>folder_open</mat-icon>
    </button>
  </div>
  <div class="list">
    <app-folder-list-element
      *ngFor="let folder of folders$ | async"
      [node]="folder"
      (click)="folderSelected(folder)"
      class="clickable"
      (drop)="endDrag($event, folder)"
      (dragover)="allowDrop($event)"
      (dragleave)="preventDrop($event)"
    ></app-folder-list-element>
    <app-device-list-element
      #deviceEls
      *ngFor="let device of devices$ | async"
      [device]="device"
      (click)="deviceSelected(device)"
      class="clickable"
    ></app-device-list-element>
    <app-action-buttons
      class="actions"
      *ngIf="actionButtonsEnabled"
      (addDevice)="addDevice()"
      (addFolder)="addFolder()"
      (addDevices)="addDevices()"
    ></app-action-buttons>
  </div>
</ng-container>
