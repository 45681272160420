<div class="wrapper">
  <div class="header">
    <div class="back">
      <button
        mat-icon-button
        *ngIf="activeStep.step > 1"
        (click)="navigate(activeStep.step - 1)"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="title">
      <h2>{{ activeStep.title }}</h2>
    </div>

    <div class="index">
      <span class="active">{{ activeStep.step }}</span>
      <span class="total">{{ activeStep.totalSteps }}</span>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="footer">
    <ng-container
      *ngIf="activeStep.step < activeStep.totalSteps; else elseTemplate"
    >
      <button
        mat-button
        class="next mat-elevation-z3"
        (click)="navigate(activeStep.step + 1)"
        [disabled]="!stepValid"
      >
        Next
      </button>
    </ng-container>
    <ng-template #elseTemplate>
      <button
        mat-raised-button
        class="next"
        (click)="submitted.emit()"
        [disabled]="!stepValid"
      >
        Validate
      </button>
    </ng-template>
  </div>
</div>
