<section class="filter mat-elevation-z4">
  <div *ngIf="(screensizeIndex$ | async) >= 4" class="filter-banner">
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </div>
  <mat-expansion-panel
    *ngIf="(screensizeIndex$ | async) < 4"
    class="mat-elevation-z0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Filter </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </mat-expansion-panel>
</section>

<mat-sidenav-container>
  <mat-sidenav
    mode="side"
    closed
    fixedInViewport="false"
    class="sidenav"
    #brands
  >
    <div class="brand-list">
      <app-brand-item
        *ngFor="let brand of brands$ | async"
        [brand]="brand"
        [clickable]="auth.isAdmin()"
        (click)="auth.isAdmin() ? editBrand(brand, updateBrandTmpl) : null"
      ></app-brand-item>
      <button
        mat-fab
        color="primary"
        class="floating-brand"
        *ngIf="auth.isMaintainer()"
        matTooltipPosition="below"
        matTooltip="add a new brand"
        (click)="createBrand()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div class="brands-bar" (click)="brands.toggle()" *ngIf="auth.isAdmin()">
      <h3 class="rotate">Brands</h3>
    </div>
    <div *ngIf="!auth.isAdmin()"></div>
    <ng-container
      *appLoader="let models; on: filteredModels$; with: modelLoader"
    >
      <app-no-result *ngIf="!models?.length"></app-no-result>
      <div class="model-tiles" *ngIf="tileView">
        <app-model-item
          *ngFor="let model of models"
          [model]="model"
          [editable]="true"
          (deleted)="deleteModel($event)"
          (click)="viewDetails(model.device_type_id)"
        ></app-model-item>
      </div>

      <div class="list-view" *ngIf="!tileView">
        <div class="list-header mat-elevation-z4">
          <div class="list-row">
            <div class="list-col">Name</div>
            <div class="list-col">Brand</div>
            <div class="list-col">Source</div>
            <div class="list-col">Actions</div>
          </div>
        </div>
        <div class="list-body">
          <div
            *ngFor="let model of filteredModels$ | async"
            class="list-row mat-elevation-z4"
            (click)="viewDetails(model.device_type_id)"
          >
            <div class="list-col">
              {{ model.metadata.label }}
            </div>
            <div class="list-col">{{ model.brand.brand_id }}</div>

            <div class="list-col">{{ model.source.source_id }}</div>
            <div class="list-col">
              <div class="actions">
                <ng-container *ngIf="model.metadata.created_by_api">
                  <button
                    *ngIf="auth.isAdmin()"
                    mat-mini-fab
                    color="primary"
                    (click)="editModel(model.device_type_id)"
                    matTooltipPosition="below"
                    matTooltip="edit device type"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    *ngIf="auth.isAdmin()"
                    mat-mini-fab
                    color="warn"
                    (click)="deleteModel(model.device_type_id)"
                    matTooltipPosition="below"
                    matTooltip="delete device type"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<button
  mat-fab
  color="primary"
  class="floating-button"
  *ngIf="auth.isAdmin()"
  matTooltipPosition="below"
  matTooltip="add a new device type"
  (click)="createDeviceType()"
>
  <mat-icon>add</mat-icon>
</button>

<ng-template #formTpl>
  <app-model-filter
    [manufacturers]="brands$ | async"
    [models]="models$ | async"
    [sources]="sources$ | async"
    [tags]="dataTags$ | async"
    (modelsFiltered)="onFilterChanged($event)"
    (tileViewActive)="setViewSelection($event)"
  ></app-model-filter>
</ng-template>
