<div class="mapping-box input border-blue">
  <p>{{ field.name }}</p>
</div>
<div
  *ngIf="!field.deleted && input.valid && !field.duplicate_output"
  class="link-box"
>
  <div class="half-line"></div>
  <div class="half-line"></div>
</div>
<div
  *ngIf="field.deleted || input.invalid || field.duplicate_output"
  class="link-box"
>
  <div class="half-line"></div>
  <mat-icon class="line-stop">clear</mat-icon>
</div>
<div
  class="mapping-box"
  [ngClass]="{
    'border-blue': !field.deleted && input.valid && !field.duplicate_output
  }"
>
  <mat-form-field>
    <input
      matInput
      [formControl]="input"
      *ngIf="!select"
      (blur)="setSelect(true)"
      autoFocus
      [errorStateMatcher]="matcher"
    />
    <mat-select
      [formControl]="input"
      *ngIf="select"
      [errorStateMatcher]="matcher"
    >
      <mat-option value="{{ input.value }}">
        {{ input.value }}
      </mat-option>
      <mat-option *ngFor="let opt of selectOptions" value="{{ opt.key_name }}">
        {{ opt.key_name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="input.hasError('pattern')">
      Allowed special characters: / - _
    </mat-error>
    <mat-error *ngIf="input.hasError('required')">
      This field is can't be left empty!
    </mat-error>
  </mat-form-field>
  <div class="toolbox">
    <button
      mat-icon-button
      (click)="setSelect(!select)"
      [disabled]="field.deleted"
    >
      <mat-icon *ngIf="select">edit</mat-icon>
      <mat-icon *ngIf="!select">save</mat-icon>
    </button>
    <button mat-icon-button (click)="toggleDeleted()">
      <mat-icon *ngIf="!field.deleted">delete</mat-icon>
      <mat-icon *ngIf="field.deleted">restore_from_trash</mat-icon>
    </button>
  </div>
</div>
