<div class="banner mat-typography mat-elevation-z3">
  <h2>Create a new device</h2>
  <button
    mat-icon-button
    [routerLink]="['/home/device/manager']"
    [queryParams]="{ path: deviceManagement.path }"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>
<section class="form-section" *appLoader="types$">
  <router-outlet></router-outlet>
</section>
