import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { DeviceDto } from 'src/models/device.models';

interface MetaData {
  key: string;
  value: string;
}

const nestedForms = { mdKeys: 'metaDataKeys', metadata: 'metaData' };

@Component({
  selector: 'app-metadata-form',
  templateUrl: './metadata-form.component.html',
  styleUrls: ['./metadata-form.component.scss'],
})
export class MetadataFormComponent {
  @Input() set device(value: DeviceDto) {
    if (value) {
      this.addEditableMetadataFields(value);
    }
  }
  @Output() metadataChange = new EventEmitter<UntypedFormGroup>();
  public metaData: MetaData[] = [];
  public metadataForm = new UntypedFormGroup({
    metaDataKeys: new UntypedFormArray([]),
    metaData: new UntypedFormGroup({}),
  });

  get metadataKeys(): UntypedFormArray {
    return this.metadataForm.get(nestedForms.mdKeys) as UntypedFormArray;
  }

  get metaDataFormGroup(): UntypedFormGroup {
    return this.metadataForm.get(nestedForms.metadata) as UntypedFormGroup;
  }

  private addEditableMetadataFields(device: DeviceDto) {
    const {
      lat,
      lng,
      name,
      comment,
      EUI,
      applicationEUI,
      activationType,
      deviceProfileId,
      networkAdress,
      ref,
      routeRefs,
      processingStrategyId,
      connectivityPlanId,
      networkAddress,
      deviceClass,
      updated_by,
      updated_at,
      created_by,
      created_at,
      ...editableMetadata
    } = device.metadata;

    Object.keys(editableMetadata).forEach((key) => {
      this.onAddMetaDataInput(key, editableMetadata[key].toString());
    });
  }

  public onKeyChange(newKey: string, index: number) {
    if (!this.metadataForm.contains(newKey)) {
      this.metaData[index].key = newKey;
      this.updateCreationForm();
      this.metadataChange.emit(this.metaDataFormGroup);
    }
  }

  public onValueChange(newValue: string, index: number) {
    this.metaData[index].value = newValue;
    this.updateCreationForm();
    this.metadataChange.emit(this.metaDataFormGroup);
  }

  public onAddMetaDataInput(newKey: string = '', newValue: string = '') {
    const control = new UntypedFormControl(newKey, Validators.required);
    this.metaData.push({ key: newKey, value: newValue });
    this.metadataKeys.push(control);
    this.updateCreationForm();
  }

  public onRemoveMetaDataInput(index: number) {
    this.metaData.splice(index, 1);
    this.metadataKeys.removeAt(index);
    this.updateCreationForm();
  }

  private updateCreationForm() {
    this.metadataForm.controls.metaData = this.mapMetaDataToFormGroup();
    this.metadataForm.updateValueAndValidity();
  }

  private mapMetaDataToFormGroup() {
    const formGroup = new UntypedFormGroup({});
    this.metaData.forEach((data) =>
      formGroup.addControl(
        data.key,
        new UntypedFormControl(data.value, Validators.required),
      ),
    );
    return formGroup;
  }
}
