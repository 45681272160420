import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/state/auth/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(private auth: AuthService) {}

  login() {
    this.auth.login();
  }
}
