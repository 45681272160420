import { AbstractControl, ValidationErrors } from '@angular/forms';
import { parse } from 'mathjs';

export function formulaValidator(
  control: AbstractControl<string>,
): ValidationErrors | null {
  try {
    parse(control.value);
    return null;
  } catch {
    return { invalidFormula: { value: control.value } };
  }
}
