import { Injectable } from '@angular/core';
import { FileTypes } from 'src/models/download.models';
import { FileExtensions } from './../../../models/download.models';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  convertToCSV(data: string[]): string {
    return '\ufeff' + data.join('\r\n');
  }

  downloadFile(
    data: string,
    fileName: string,
    fileType: FileTypes,
    fileExtension: FileExtensions,
  ) {
    const blob = new Blob([data], { type: fileType });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', fileName + fileExtension);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
