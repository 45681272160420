import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { LoaderComponent } from '../../components/loader/loader.component';

@Directive({
  selector: '[appLoader]',
})
export class LoaderDirective implements OnChanges, OnDestroy {
  @Input() appLoaderOn: Observable<unknown> | unknown;
  @Input() appLoader: Observable<unknown> | unknown;

  @Input() appLoaderWith?: (v: unknown) => boolean;

  sub: Subscription;

  constructor(
    private viewRef: ViewContainerRef,
    private tplRef: TemplateRef<{ $implicit: unknown }>,
    private factory: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef,
  ) {
    this.viewRef.clear();
  }

  ngOnChanges() {
    const condition = this.appLoaderOn ?? this.appLoader;
    this._createLoaderTemplate();

    this.sub?.unsubscribe();

    if (condition instanceof Observable)
      this.sub = condition
        .pipe(
          filter((v) => (this.appLoaderWith ? this.appLoaderWith(v) : !!v)),
          first(),
        )
        .subscribe((v) => this._createViewTemplate(v));
    else if (condition) this._createViewTemplate(condition);
    else this._createLoaderTemplate();
  }

  private _createViewTemplate(v: unknown) {
    this.viewRef.clear();
    const ref = this.viewRef.createEmbeddedView(this.tplRef);
    ref.context.$implicit = v;
    this.cdRef.markForCheck();
  }

  private _createLoaderTemplate() {
    this.viewRef.clear();
    const component = this.factory.resolveComponentFactory(LoaderComponent);
    this.viewRef.createComponent(component);
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
