<mat-tab-group>
  <mat-tab label="General">
    <div class="general">
      <p class="label">Device ID:</p>
      <p>{{ device.device_id }}</p>
      <p class="label">Manufacturer:</p>
      <p>{{ device.device_type.brand.brand_id }}</p>
      <p class="label">Model:</p>
      <p>{{ device.device_type.metadata.label }}</p>
      <p class="label">Data Source:</p>
      <p>{{ device.source_id | titlecase }}</p>
      <p class="label">Functions:</p>
      <div class="functions-box">
        <p
          class="device-function"
          *ngFor="let function of device.device_type.metadata.functions"
        >
          {{ function | titlecase }}
        </p>
      </div>
      <ng-container *ngIf="device.site?.metadata.userAccessFlags">
        <p class="label">Control Groups:</p>
        <div class="functions-box">
          <p class="control_group" *ngFor="let group of device.attribute_tags">
            {{ group.tag_id | titlecase }}
          </p>
        </div>
      </ng-container>
    </div>
  </mat-tab>
  <mat-tab label="Metadata">
    <div class="general">
      <ng-container *ngFor="let key of getSortedObjectKeys(device.metadata)">
        <p class="label" id="key">{{ replaceUnderScore(key) | titlecase }}:</p>
        <p *ngIf="isTimeStamp(key)">
          {{ device.metadata[key] | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ' }}
        </p>
        <p *ngIf="!isTimeStamp(key)">
          {{ device.metadata[key] }}
        </p>
      </ng-container>
      <p *ngIf="isIOTcoreDevice(device.source_id)" class="label">
        Device Region:
      </p>
      <p *ngIf="isIOTcoreDevice(device.source_id)">
        {{ device.device_region | titlecase }}
      </p>
    </div>
  </mat-tab>
  <mat-tab label="Downlink History" *ngIf="canSendDownlink()">
    <div class="downlink-history">
      <h4
        *ngIf="
          (downlinks$ | async) === undefined ||
          (downlinks$ | async)?.length === 0
        "
        class="empty-message"
      >
        No downlink available for this device.
      </h4>
      <table
        *ngIf="(downlinks$ | async)?.length !== 0"
        mat-table
        [dataSource]="downlinks$"
      >
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let downlink">
            {{
              timestampToDate(downlink.timestamp)
                | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ'
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="payload">
          <th mat-header-cell *matHeaderCellDef>Payload</th>
          <td mat-cell *matCellDef="let downlink">{{ downlink.payload }}</td>
        </ng-container>
        <ng-container matColumnDef="port">
          <th mat-header-cell *matHeaderCellDef>Port</th>
          <td mat-cell *matCellDef="let downlink">
            {{ downlink.port }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let downlink">
            <button
              mat-icon-button
              color="primary"
              (click)="onSendDownlink(downlink)"
            >
              <mat-icon>replay</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="downlinkHistoryColumns; sticky: true"
          class="header-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let device; columns: downlinkHistoryColumns"
          class="device-row"
        ></tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
