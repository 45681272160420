import { Component, Input } from '@angular/core';
import { Brand } from 'src/models/device-type.models';

@Component({
  selector: 'app-brand-item',
  templateUrl: './brand-item.component.html',
  styleUrls: ['./brand-item.component.scss'],
})
export class BrandItemComponent {
  @Input() brand: Brand;
  @Input() clickable: boolean;

  constructor() {}
}
