import {
  Component,
  Input
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DownloadService } from 'src/app/services/util/download.service';
import { RouteDto } from 'src/models/data-routing.models';
import { FileExtensions, FileTypes } from 'src/models/download.models';
import { DataStoreService } from './../../../../../services/state/data/data-store.service';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
})
export class DataListComponent {
  private _displayedData: string[];
  tooltip = 'copy';
  groupedRoutes: GroupedRoutes[];
  get displayedData() {
    return this._displayedData;
  }

  @Input() set displayedData(v: string[]) {
    this._displayedData = v;

    const mapper = {};

    this._displayedData.reduce(
      (_: void, curr) => {
        const crumbs = curr.split(',')[1].split('/');
        if (crumbs[0] === '') crumbs.splice(0, 2, '/' + crumbs[1]);
        let ref = mapper;
        for (const crumb of crumbs) ref = ref[crumb] = ref[crumb] ?? {};
        return;
      },
      void 0,
    );

    const recur = (ref: object) =>
      Object.entries(ref).map(([key, value]) => ({
        value: key,
        children: recur(value),
      }));

    this.groupedRoutes = recur(mapper);
  }
  @Input() routing: RouteDto;

  constructor(
    private download: DownloadService,
    private data: DataStoreService,
  ) {}

  onCopyToClipBoard(text: string, toolTip: MatTooltip) {
    this.tooltip = 'copied to clipboard';
    setTimeout(() => toolTip.show());
    setTimeout(() => {
      toolTip.hide();
    }, 2000);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  exportAsCSV(): void {
    const site = this.data.sites!.find(site => site.tag_id === this.routing.pattern.split("/")[1])
    const fileName = site ? `${site.metadata.iso_code} - ${site.metadata.site_long_name}_${this.routing.name}_IoT-paths_${new Date().toISOString()}` : `${this.routing.name}_IoT-paths_${new Date().toISOString()}`;

    this.download.downloadFile(
      this.download.convertToCSV(this._displayedData),
      fileName,
      FileTypes.CSV_TYPE,
      FileExtensions.CSV_EXTENSION,
    );
  }

  resetTooltip() {
    this.tooltip = 'copy';
  }

  getDeviceName(id: string): string {
    const device = this.data.getDeviceByID(id);
    return device ? device.metadata.name : '';
  }
}

type GroupedRoutes = { value: string; children: GroupedRoutes[] };
