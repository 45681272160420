import {
  Component,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { DeviceDto } from 'src/models/device.models';
import { DeviceManagementService } from '../../device-management.service';

@Component({
  selector: 'app-device-list-element',
  templateUrl: './device-list-element.component.html',
  styleUrls: ['./device-list-element.component.scss'],
})
export class DeviceListElementComponent {
  @Input() device: DeviceDto;

  @HostBinding('attr.draggable') private isDraggable = true;

  @ViewChild('dragBoxEl') dragBoxEl: MatCheckbox;

  constructor(private service: DeviceManagementService) {}

  @HostListener('dragstart', ['$event'])
  startDrag(event: DragEvent) {
    this.service.startDraggingItem(event, this.device);
  }

  dragboxChange(checked: boolean) {
    this.service.toggleDragItem(this.device, checked);
  }

  toggleCheckbox(checked: boolean) {
    this.dragBoxEl.writeValue(checked);
  }
}
