<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="hierarchy-tree"
  *ngIf="dataSource"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    [id]="node.id"
    class="device"
  ></mat-tree-node>
  <mat-nested-tree-node
    *matTreeNodeDef="let node; when: isFolder"
    [id]="node.id"
  >
    <li [ngClass]="{ selected: nodeIsSelected(node) }">
      <div
        (click)="nodeSelected(node)"
        class="tree-node"
        (drop)="endDrag($event, node)"
        (dragover)="allowDrop($event)"
        (dragleave)="preventDrop($event)"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.name"
          (click)="nodeSelected(node)"
        >
          <mat-icon>
            {{ treeControl.isExpanded(node) ? 'folder_open' : 'folder' }}
          </mat-icon>
        </button>
        {{ node.name }}
        <div class="spacer"></div>
        <button
          mat-icon-button
          [attr.aria-label]="'toggle ' + node.name"
          matTreeNodeToggle
          (click)="nodeSelected(node)"
        >
          <mat-icon *ngIf="!nodeIsSelected(node)">
            {{
              treeControl.isExpanded(node) ? 'arrow_drop_up' : 'arrow_drop_down'
            }}
          </mat-icon>
          <mat-icon *ngIf="nodeIsSelected(node)"> arrow_right </mat-icon>
        </button>
      </div>
      <ul [class.hidden]="!treeControl.isExpanded(node)" class="subfolder">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
