import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { ActivatedRoute, ResolveFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map, tap } from 'rxjs/operators';
import { DeviceManagementService } from 'src/app/pages/main/device-management/device-management.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { DeviceTypeDto } from 'src/models/device-type.models';
import { DeviceCreationService } from '../device-creation.service';

@Component({
  selector: 'app-model-choice',
  templateUrl: './model-choice.component.html',
  styleUrls: ['./model-choice.component.scss'],
})
export class ModelChoiceComponent implements OnInit {
  screensizeIndex$ = this.interfaceState.breakpointIndex$;
  filteredModels$ = this.data.deviceTypes$;
  models$ = this.data.deviceTypes$;
  dataTags$ = this.data.dataTags$;
  sources$ = this.data.sources$;
  brands$ = this.data.brands$;
  creationParams = { step: 1 };

  static getResolver(): Record<string, ResolveFn<unknown>> {
    return {
      sites: () =>
        inject(DataStoreService).sites ?? inject(DataLoaderService).loadSites(),
    };
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
    private deviceCreation: DeviceCreationService,
    private deviceManagement: DeviceManagementService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.pathQueryParamHandler$.subscribe();
  }

  public onFilterChanged(models: DeviceTypeDto[]): void {
    this.filteredModels$ = of(models);
  }

  public openFilterDialog(templateRef: TemplateRef<unknown>) {
    this.dialog.open(templateRef, { minWidth: 600 });
  }

  public onModelSelected(model: DeviceTypeDto) {
    if (
      model.source.source_id === 'firefly' &&
      !this.deviceManagement.isFireflyConfiguredInSelectedSite()
    ) {
      this.snackbar.open(
        'Operation impossible: the current site misses a configured Firefly organization.',
        'Close',
      );
      return;
    }
    const url = this.deviceCreation.getRouteForDeviceDataSource(
      model.source.source_id,
    );
    this.deviceCreation.setSelectedDeviceType(model);
    if (url) {
      this.router.navigate([url], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: { step: 1 },
      });
    }
  }

  private get pathQueryParamHandler$(): Observable<string> {
    return this.route.queryParams.pipe(
      map((params) => params.path),
      filter((path) => path.split('/').length < 2),
      tap((path) =>
        this.router.navigate(['/home/device/manager'], {
          queryParams: { path },
        }),
      ),
    );
  }
}
