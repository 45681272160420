<div class="title">
  <h3>Order Data</h3>
  <p>Assign data to the columns of your {{ tableName || 'Google Sheet' }}</p>
</div>
<ng-container [formGroup]="dataOrderForm">
  <div class="colum-list" formArrayName="data">
    <div
      class="column"
      *ngFor="let control of dataArray.controls; let i = index"
    >
      <div class="column-title" [ngClass]="{ assigned: !!control.value }">
        <p>{{ colTitles[i] }}</p>
      </div>
      <mat-form-field appearance="outline">
        <input matInput [formControlName]="i" />
      </mat-form-field>
    </div>
  </div>
</ng-container>

<button
  mat-raised-button
  color="primary"
  class="add-column"
  (click)="addColumn()"
  [disabled]="disabled"
>
  <mat-icon>add</mat-icon>
</button>
