<h2 *ngIf="!interf" mat-dialog-title>Create a new interface</h2>
<h2 *ngIf="interf" mat-dialog-title>
  Edit interface: {{ interf?.metadata?.name ?? '' }}
</h2>
<div mat-dialog-content>
  <div class="form-wrapper" [formGroup]="form">
    <div class="centered-content">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" [maxLength]="40" />
        </mat-form-field>
      </div>
      <ng-container formArrayName="sites">
        <ng-container *ngFor="let siteForm of sites.controls; let i = index">
          <div class="row-site">
            <mat-form-field appearance="outline">
              <mat-label>Site</mat-label>
              <mat-select [formControlName]="i">
                <mat-option
                  *ngFor="let site of sites$ | async"
                  [value]="site.tag_id"
                  [disabled]="siteAlreadySelected(site.tag_id, i)"
                >
                  {{ site?.metadata?.iso_code }} -
                  {{ site?.metadata?.site_long_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              mat-stroked-button
              class="remove-site-button"
              color="basic"
              (click)="removeSite(i)"
              [disabled]="sites.controls.length <= 1"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <div class="add-site-button">
        <button
          mat-stroked-button
          class="add-site-button"
          color="primary"
          (click)="addSite()"
        >
          <mat-icon class="add-site-link">add</mat-icon>
          Add a site
        </button>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Destination</mat-label>
          <mat-select formControlName="destination">
            <mat-option
              *ngFor="let destination of routingDestinationTypes"
              [value]="destination.id"
              [disabled]="destination.id !== 'mes'"
            >
              {{ destination.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Topic</mat-label>
          <input matInput formControlName="topic" [maxLength]="80" />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    color="primary"
    (click)="submit()"
    cdkFocusInitial
    [disabled]="
      form.invalid ||
      saving ||
      (interf && interf.metadata.destination !== 'mes')
    "
  >
    Confirm
  </button>
</div>
