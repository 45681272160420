<div mat-dialog-content>
  <h2>Update device information</h2>
  <form [formGroup]="editForm">
    <mat-form-field appearance="outline">
      <mat-label>Device Name</mat-label>
      <input matInput formControlName="name" autoFocus required />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
    <ng-container
      *ngIf="
        data.device.site?.metadata.userAccessFlags && !!userAccessFlags.length
      "
    >
      <mat-expansion-panel class="control-groups">
        <mat-expansion-panel-header class="control-groups-header">
          <mat-panel-title class="control-groups-header-title">
            Control Groups
            <mat-icon
              matTooltip="
              Export Control groups can be selected to prevent the diffusion of data to people outside of these controls groups.
              Only the control group you have access to are visible here."
            >
              help_outline
            </mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-checkbox
            *ngFor="let flag of userAccessFlags; let i = index"
            class="checkbox"
            color="primary"
            [checked]="deviceHasFlag(flag)"
            (change)="onFlagChange($event.checked, flag)"
          >
            {{ flag }}
          </mat-checkbox>
        </div>
      </mat-expansion-panel>
    </ng-container>
    <div class="key-btn-box" *ngIf="isIotCore()">
      <button
        mat-flat-button
        color="primary"
        class="key-btn"
        (click)="fileInput.click()"
      >
        Update Public Key File
      </button>
      <app-loader *ngIf="loading"></app-loader>
      <p *ngIf="fileUploadForm.hasError('certFormat')" class="warning">
        Please upload a valid X.509 certificate!
      </p>
      <div *ngIf="certificate$ | async as cert" class="file-preview">
        <mat-icon class="file-icon">task</mat-icon>
        <p>{{ cert.name }}</p>
      </div>
    </div>
    <app-select-position-map
      [device]="data.device"
      (setPosition)="onSetPosition($event)"
    ></app-select-position-map>
    <ng-container formGroupName="technical">
      <input
        #fileInput
        hidden="true"
        type="file"
        onclick="this.value=null"
        (change)="fileInputChange($event)"
        accept=".pem, .cer"
      />
    </ng-container>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-button
    (click)="onSubmit()"
    [disabled]="!editForm.valid"
    class="submit"
  >
    Save
  </button>
</div>
