<form [formGroup]="form" *ngIf="form" class="filter">
  <ng-container formGroupName="filter">
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput formControlName="keyword" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Site</mat-label>
      <mat-select formControlName="site">
        <mat-option [value]="0">All</mat-option>
        <mat-option *ngFor="let site of sites" [value]="site.tag_id">
          {{ site?.metadata?.iso_code }} -
          {{ site?.metadata?.site_long_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Destination</mat-label>
      <mat-select formControlName="destination">
        <mat-option [value]="0">All</mat-option>
        <mat-option *ngFor="let dest of destinations" [value]="dest">{{
          dest
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <mat-slide-toggle
    formControlName="tileView"
    color="primary"
    class="filter-field"
    >Tile View</mat-slide-toggle
  >
</form>
