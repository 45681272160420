import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, merge, of } from 'rxjs';
import { first, startWith, switchMap, tap } from 'rxjs/operators';

import { routingDestinationTypes } from 'src/app/pages/main/routing-list/routing-creation/routing-creation.config';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { Interface } from 'src/models/data-routing.models';

@Component({
  selector: 'app-interface-filter',
  templateUrl: './interface-filter.component.html',
})
export class InterfaceFilterComponent implements OnInit {
  @Input() interfaces: Interface[];
  @Output() interfacesFiltered = new EventEmitter<Interface[]>();
  form: UntypedFormGroup;

  sites$ = this.data.sites$;
  routingDestinationTypes = routingDestinationTypes;

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
  ) {
    this.loadData();
  }

  ngOnInit(): void {
    this.form = this.buildFilterForm();
    this.handleInputs.subscribe();
  }

  private loadData() {
    combineLatest([
      this.data.sites$.pipe(
        switchMap((sites) => (sites ? of(sites) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  private get handleInputs() {
    return merge(
      this.form.valueChanges,
      this.data.interfaces$.pipe(
        tap((interfaces) => {
          this.interfaces = interfaces ?? [];
        }),
      ),
    ).pipe(
      startWith(this.form.value),
      tap(() => {
        this.emitFilteredInterfaces();
      }),
    );
  }

  private emitFilteredInterfaces() {
    let result = this.interfaces;
    result = this.searchFilter(result);
    result = this.siteFilter(result);
    result = this.destinationFilter(result);

    this.interfacesFiltered.emit(result);
  }

  private searchFilter(interfaces: Interface[]): Interface[] {
    const keyword = this.form.value.search?.toLowerCase();
    return !keyword
      ? interfaces
      : interfaces.filter((interf) =>
          interf.metadata.name?.toLowerCase().includes(keyword),
        );
  }

  private siteFilter(interfaces: Interface[]): Interface[] {
    const keyword = this.form.value.site?.toLowerCase();
    return !keyword
      ? interfaces
      : interfaces.filter((interf) =>
          interf.tags.find((site) => site?.toLowerCase() === keyword),
        );
  }

  private destinationFilter(interfaces: Interface[]): Interface[] {
    const keyword = this.form.value.destination?.toLowerCase();
    return !keyword
      ? interfaces
      : interfaces.filter(
          (interf) => interf.metadata.destination?.toLowerCase() === keyword,
        );
  }

  private buildFilterForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      search: new UntypedFormControl(''),
      site: new UntypedFormControl(''),
      destination: new UntypedFormControl(''),
    });
  }
}
