import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const writeRightsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const path = route.queryParamMap.get('path');
  const hasWriteRights = path
    ? inject(AuthService).hasWriteRightsForProject(path)
    : false;
  return hasWriteRights || inject(Router).createUrlTree(['/home']);
};
