import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { icon, map, marker, tileLayer } from 'leaflet';
import { environment } from 'src/environments/environment';

const mapOptions = {
  layers: [
    tileLayer(environment.openStreetMap.layerTilesUrl, {
      maxZoom: 18,
      attribution: environment.openStreetMap.attribution,
    }),
  ],
};

@Component({
  selector: 'app-select-position',
  templateUrl: './select-position.component.html',
  styleUrls: ['./select-position.component.scss'],
})
export class SelectPositionComponent implements AfterViewInit, OnDestroy {
  @Input() set location(value: { _latitude: number; _longitude }) {
    if (value._latitude && value._longitude) {
      this.center = [value._latitude, value._longitude];
      if (this.map && !this.initialized) {
        this.map.setView(this.center);
        this.initialized = true;
      }
      if (this.marker) {
        this.marker.setLatLng(this.center);
      }
    }
  }
  @Output() setPosition = new EventEmitter<L.LatLng>();
  private initialized = false;
  private map: L.Map;
  private marker: L.Marker;
  private center: L.LatLngExpression = [44.7931165, -0.6236336];

  constructor() {}

  ngAfterViewInit() {
    if (document.getElementById('mapSelect')) {
      this.map = map('mapSelect', mapOptions).setView(this.center, 5);
      this.marker = marker(this.center, {
        icon: icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'assets/img/marker-icon.png',
          shadowUrl: 'assets/img/marker-shadow.png',
        }),
      }).addTo(this.map);

      this.map.on('click', (e) => {
        this.marker.setLatLng((e as L.LeafletMouseEvent).latlng);
        this.setPosition.emit((e as L.LeafletMouseEvent).latlng);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
