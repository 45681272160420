<h1 mat-dialog-title class="dialog-title">Send Downlink</h1>
<div mat-dialog-content>
  <div *ngIf="isHexaDecimalPayload" [formGroup]="downlinkForm">
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Payload (Hexadecimal)</mat-label>
      <textarea matInput rows="5" formControlName="payloadHex"></textarea>
      <mat-error *ngIf="downlinkForm.get('payloadHex')!.hasError('pattern')">
        Payload has do be an Hexadecimal string
      </mat-error>
      <mat-error *ngIf="downlinkForm.get('payloadHex')!.hasError('evenLength')">
        Payload length has to be even
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Port</mat-label>
      <input matInput formControlName="targetPorts" />
      <mat-error *ngIf="downlinkForm.get('targetPorts')!.hasError('pattern')">
        Port has do be a number between 1 and 223
      </mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="isJsonPayload" mat-dialog-content class="downlink-content">
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Payload (JSON)</mat-label>
      <textarea
        matInput
        rows="5"
        [formControl]="downlinkIotCoreControl"
      ></textarea>
      <mat-error *ngIf="downlinkIotCoreControl.hasError('required')">
        Payload required
      </mat-error>
      <mat-error *ngIf="downlinkIotCoreControl.hasError('json')">
        Payload is not a valid JSON
      </mat-error>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button [mat-dialog-close]="false" class="dialog-btn">
    Cancel
  </button>
  <button
    mat-button
    (click)="onSend()"
    class="dialog-btn"
    [disabled]="isSendButtonDisabled()"
  >
    Send
  </button>
</div>
