<div class="md-wrapper">
  <form [formGroup]="metadataForm">
    <div class="md-row" *ngFor="let item of metaData; let i = index">
      <div formArrayName="metaDataKeys" class="md-input">
        <mat-form-field appearance="outline">
          <mat-label>Key</mat-label>
          <input
            matInput
            [id]="i"
            [formControlName]="i"
            required
            (input)="onKeyChange($event.target.value, i)"
          />
        </mat-form-field>
      </div>
      <div formGroupName="metaData" class="md-input">
        <mat-form-field appearance="outline">
          <mat-label>Value </mat-label>
          <input
            matInput
            [formControlName]="item.key"
            required
            (input)="onValueChange($event.target.value, i, item.key)"
          />
        </mat-form-field>
      </div>
      <button
        mat-icon-button
        (click)="onRemoveMetaDataInput(i)"
        class="delete-btn"
      >
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </form>
</div>
<div class="actions">
  <button mat-button (click)="onAddMetaDataInput()">Add Metadata</button>
</div>
