<div mat-dialog-content>
  <p>Please enter a name for the new Folder!</p>
  <form [formGroup]="nameForm">
    <mat-form-field class="input" hintLabel="Allowed special Characters: - _">
      <input
        matInput
        formControlName="name"
        #name
        pattern="[a-zA-Z0-9\-\_]*"
        (keyup.enter)="onSubmit()"
      />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="name.value"
    [disabled]="!nameForm.valid"
    class="submit"
  >
    Ok
  </button>
</div>
