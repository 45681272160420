import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DevicesService } from 'src/app/services/http/devices.service';
import { Variable } from 'src/models/device-type.models';
import { ParserCreationService } from './../parser-creation.service';
import { SingleParserDialogComponent } from './single-parser-dialog/single-parser-dialog.component';

@Component({
  selector: 'app-multi-parser-editor',
  templateUrl: './multi-parser-editor.component.html',
  styleUrls: ['./multi-parser-editor.component.scss'],
})
export class MultiParserEditorComponent {
  bluePrint = this.parserCreation.hexBlueprint;
  form = this.fb.group({
    parserElements: this.fb.array([]),
    calculations: this.fb.array([]),
  });
  variables: { [key: string]: Variable } = {};

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public deviceHttp: DevicesService,
    public parserCreation: ParserCreationService,
  ) {}

  openSingleEditorDialog(id: string) {
    this.dialog.open(SingleParserDialogComponent, {
      data: {
        id: id,
        config: this.bluePrint!.config[id],
      },
    });
  }
}
