import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, merge, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { User, UserInfo } from 'src/models/user.models';
import { SiteTag } from './../../../../../models/device.models';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
})
export class UserCreationComponent implements OnInit {
  form = this.buildForm();
  displayHelp = false;
  editMode = false;
  accessLevels = [
    { name: 'Operator', level: 0 },
    { name: 'Maintainer', level: 1 },
  ];
  private readonly HOME_USERS_TEXT = 'home/users';

  sites$ = this.data.sites$;

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog,
  ) {
    this._loadData();
  }

  private _loadData() {
    this.data.sites$
      .pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
        first(),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        map(() => history.state),
        filter((state) => state.user_email),
        tap((user) => {
          this.editMode = true;
          this.form = this.buildForm(user as User);
        }),
      )
      .subscribe();
  }

  get accessForm(): UntypedFormArray {
    return this.form.get('access') as UntypedFormArray;
  }

  public addAccessFormFields(): void {
    const newFields = new UntypedFormGroup({
      site: new UntypedFormControl(''),
      level: new UntypedFormControl(null),
    });
    this.accessForm.push(newFields);
  }

  public removeAccessFromFields(index: number): void {
    index > 0 || this.accessForm.controls.length > 1
      ? this.accessForm.removeAt(index)
      : this.accessForm.controls[index].setValue({ site: '', level: '' });
  }

  public unchosenSites$(accessFormFieldIndex: number): Observable<SiteTag[]> {
    return merge(this.form.valueChanges, this.data.sites$).pipe(
      switchMap(() => this.data.sites$),
      map((sites) => {
        const selectedSites = this.form.value.access
          .filter((access, index) => index !== accessFormFieldIndex)
          .map((access) => access.site);
        return sites
          ? sites.filter((site) => !selectedSites.includes(site.tag_id))
          : [];
      }),
    );
  }

  public cancel(): void {
    this.router.navigateByUrl(this.HOME_USERS_TEXT);
  }

  public submit(): void {
    const userInfo = this.formatInput();
    !this.editMode
      ? this.dataLoader.createUser(userInfo).subscribe(() => {
          this.router.navigateByUrl(this.HOME_USERS_TEXT);
        })
      : this.dataLoader.updateUser(userInfo).subscribe(() => {
          this.router.navigateByUrl(this.HOME_USERS_TEXT);
        });
  }

  public delete(templateRef: TemplateRef<unknown>) {
    const dialogRef = this.dialog.open(templateRef);

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.dataLoader.deleteUser(this.form.value.email).subscribe(() => {
          this.router.navigateByUrl(this.HOME_USERS_TEXT);
        });
      }
    });
  }

  public toggleHelp(): void {
    this.displayHelp = !this.displayHelp;
  }

  public siteExists(site): boolean {
    return !!this.data.sites?.find((s) => s.tag_id === site);
  }

  private buildForm(user?: User): UntypedFormGroup {
    let userAccessForm = [
      new UntypedFormGroup({
        site: new UntypedFormControl(''),
        level: new UntypedFormControl(null),
      }),
    ];

    if (user) {
      const sites = Object.keys(user.roles).filter((site) =>
        this.siteExists(site),
      );
      userAccessForm = sites.length
        ? sites.map(
            (site) =>
              new UntypedFormGroup({
                site: new UntypedFormControl(site),
                level: new UntypedFormControl(user.roles[site]),
              }),
          )
        : userAccessForm;
    }

    return new UntypedFormGroup({
      email: new UntypedFormControl(user ? user.user_email : '', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^[A-Za-z0-9._%+-]+@solvay.com$|^[A-Za-z0-9._%+-]+@syensqo.com$/,
        ),
      ]),
      administrator: new UntypedFormControl(user ? user.administrator : false),
      technical_administrator: new UntypedFormControl(
        user ? user.technical_administrator : false,
      ),
      active: new UntypedFormControl(user ? user.active : true),
      access: new UntypedFormArray(userAccessForm),
    });
  }

  private formatInput(): UserInfo {
    const formValue = this.form.value;
    const validAccess = formValue.access.filter(
      (access) => access.site && (access.level === 0 || access.level === 1),
    );

    return {
      user_email: formValue.email,
      administrator: formValue.administrator,
      technical_administrator: formValue.technical_administrator,
      active: formValue.active,
      maintainer: validAccess
        .filter((access) => access.level === 1)
        .map((access) => access.site),
      operator: validAccess
        .filter((access) => access.level === 0)
        .map((access) => access.site),
    };
  }
}
