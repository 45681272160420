<mat-radio-group [formControl]="selectedNode">
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="hierarchy-tree"
    *ngIf="dataSource"
  >
    <mat-tree-node
      *matTreeNodeDef="let node"
      [id]="node.id"
      class="tree-node"
      [ngClass]="{ selected: nodeIsSelected(node) }"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.name"
      >
        <mat-icon *ngIf="!node.isDevice && !node.isData; else noFolder">
          {{ treeControl.isExpanded(node) ? 'folder_open' : 'folder' }}
        </mat-icon>
        <ng-template #noFolder>
          <mat-icon>
            {{ node.isDevice ? 'settings_remote' : 'timeline' }}
          </mat-icon>
        </ng-template>
      </button>
      {{ node.name }}
      <div class="spacer"></div>
      <mat-radio-button [value]="node" color="accent"></mat-radio-button>
      <button mat-icon-button class="not-visible"></button>
    </mat-tree-node>
    <mat-nested-tree-node
      *matTreeNodeDef="let node; when: hasChildren"
      [id]="node.id"
    >
      <li [ngClass]="{ selected: nodeIsSelected(node) }">
        <div [ngClass]="{ selected: nodeIsSelected(node) }" class="tree-node">
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name"
          >
            <mat-icon *ngIf="!node.isDevice">
              {{ treeControl.isExpanded(node) ? 'folder_open' : 'folder' }}
            </mat-icon>
            <mat-icon *ngIf="node.isDevice"> settings_remote </mat-icon>
          </button>
          {{ node.name }}
          <div class="spacer"></div>
          <mat-radio-button [value]="node" color="accent"></mat-radio-button>
          <button
            mat-icon-button
            [attr.aria-label]="'toggle ' + node.name"
            matTreeNodeToggle
          >
            <mat-icon>
              {{
                treeControl.isExpanded(node)
                  ? 'arrow_drop_up'
                  : 'arrow_drop_down'
              }}
            </mat-icon>
          </button>
        </div>
        <ul *ngIf="treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</mat-radio-group>
