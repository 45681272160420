import { Component, Input } from '@angular/core';
import Chart from 'chart.js';
import { GatewayStats } from 'src/models/gateway.models';

@Component({
  selector: 'app-gateway-status-chart',
  templateUrl: './gateway-status-chart.component.html',
  styleUrls: ['./gateway-status-chart.component.scss'],
})
export class GatewayStatusChartComponent {
  @Input() set statistics(stats: GatewayStats) {
    if (stats) this.formatGraphData(stats);
  }
  public chart: Chart;

  constructor() {}

  formatGraphData(stats: GatewayStats) {
    const data = {
      datasets: [
        {
          data: [stats.active | 0, stats.inactive | 0],
          backgroundColor: ['#4caf50', '#f44336'],
        },
      ],
    };

    const options = {
      responsive: true,
      aspectRatio: 1.2,
      tooltips: { enabled: false },
      animation: false,
      showTooltips: false,
    };

    Object.keys(Chart.instances).forEach((key) => {
      const loInst = Chart.instances[key];
      if (loInst.canvas.id === 'gwchart') {
        loInst.destroy();
      }
    });

    this.chart = new Chart(document.getElementById('gwchart'), {
      type: 'doughnut',
      data: data,
      options: options,
    });
  }

  isEqualAndEven(a: number, b: number) {
    return a === b && a % 2 === 0;
  }

  formatDisplayedCount(a: number, b: number) {
    const equalAndEven = this.isEqualAndEven(a, b);
    return [equalAndEven ? a - 1 : a | 1, equalAndEven ? b - 1 : b];
  }
}
