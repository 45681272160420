import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { Country } from 'src/models/countries.models';
import { SiteTag } from 'src/models/device.models';
import { SiteInfo } from 'src/models/site.models';

@Component({
  selector: 'app-site-creation',
  templateUrl: './site-creation.component.html',
  styleUrls: ['./site-creation.component.scss'],
})
export class SiteCreationComponent implements OnInit {
  form = this.buildForm;
  countries$ = this.data.countries$;
  organizations$ = this.data.organizations$;
  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public site: SiteTag | undefined,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private snackBar: MatSnackBar,
    private dialogRef: MatLegacyDialogRef<SiteCreationComponent>,
  ) {}

  ngOnInit(): void {
    if (this.site) {
      this.prefillForm(this.site);
    }
    this.updateIsoCode.subscribe();
  }

  private get buildForm(): FormGroup {
    return new FormGroup({
      site_long_name: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      location: new FormGroup({
        _latitude: new FormControl(''),
        _longitude: new FormControl(''),
      }),
      organization: new FormControl('', Validators.required),
      firefly_organization: new FormControl(''),
      infra_Site: new FormControl('', Validators.required),
      iso_code: new FormControl('', Validators.required),
    });
  }

  private get updateIsoCode(): Observable<Country | undefined> {
    return this.form.controls.country.valueChanges.pipe(
      map((name) =>
        this.data.countries?.find((country) => country.name === name),
      ),
      tap((country) => this.form.controls.iso_code.setValue(country?.iso_code)),
    );
  }

  private prefillForm(site: SiteTag): void {
    const md = site.metadata;
    this.form.setValue({
      site_long_name: md?.site_long_name,
      country: md?.country,
      location: {
        _latitude:
          md?.location?._latitude === 0
            ? md?.location?._latitude
            : md?.location?._latitude || '',
        _longitude:
          md?.location?._longitude === 0
            ? md?.location?._longitude
            : md?.location?._longitude || '',
      },
      organization: md?.organization,
      firefly_organization: md?.firefly_organization || '',
      infra_Site: md?.infra_Site,
      iso_code: md?.iso_code,
    });
  }

  public get siteInfo(): SiteInfo {
    return {
      tag_id: this.site?.tag_id || '',
      metadata: this.form.value,
    };
  }

  public async submit() {
    this.saving = true;
    if (!this.site?.tag_id) {
      this.dataLoader
        .createSite(this.siteInfo)
        .pipe(
          tap(() => {
            this.snackBar.open('Site was successfully created!', 'Close');
            this.dialogRef.close();
          }),
          catchError((error) => {
            this.saving = false;
            return of(error);
          }),
        )
        .subscribe();
    } else {
      this.dataLoader
        .updateSite(this.siteInfo)
        .pipe(
          tap(() => {
            this.snackBar.open('Site was successfully updated!', 'Close');
            this.dialogRef.close();
          }),
          catchError((error) => {
            this.saving = false;
            return of(error);
          }),
        )
        .subscribe();
    }
  }

  public updateLocation(e: { lat: number; lng: number }) {
    this.form.controls.location.setValue({
      _latitude: +e.lat.toFixed(7),
      _longitude: +e.lng.toFixed(7),
    });
  }
}
