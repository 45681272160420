<div class="wrapper">
  <div class="header">
    <h3>{{ parserType || 'Not defined' }}</h3>
    <div></div>
    <button
      mat-mini-fab
      color="primary"
      (click)="onSave()"
      matTooltip="save parser modifications"
      *ngIf="parserType !== 'Built in'"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="primary"
      (click)="resetParserType()"
      matTooltip="switch parser type"
      *ngIf="parserType !== 'Built in'"
    >
      <mat-icon>autorenew</mat-icon>
    </button>
  </div>
  <app-parser-type-selection
    *ngIf="!parserType"
    [source]="model.source"
    (selectionChanged)="switchParserType($event)"
  ></app-parser-type-selection>
  <div class="built-in" *ngIf="parserType === 'Built in'">
    <h4>This device type is a built-in ressource, it can't be modified</h4>
  </div>
  <app-single-parser-editor
    [config]="parserCreation.hexBlueprint.config._single"
    [config_id]="'_single'"
    (configModified)="parserCreation.setHexBluePrintConfig('_single', $event)"
    *ngIf="parserType === 'Single Parser'"
  ></app-single-parser-editor>
  <div class="json-parser" *ngIf="parserType === 'JSON Mapping'">
    <app-json-parser-editor></app-json-parser-editor>
  </div>
  <div class="multi-parser" *ngIf="parserType === 'Multi Parser'">
    <app-multi-parser-editor></app-multi-parser-editor>
  </div>
  <div class="passthrough-parser" *ngIf="parserType === 'JSON Passthrough'">
    <div class="passthrough">
      <p>
        JSON passthrough transfers an incoming JSON object without modifying it.
        It is very flexible but less stable as the JSON mapping. This parsing
        method should only be used if you are not aware of the format of the
        incoming message or if the format can change.
      </p>
    </div>
  </div>
</div>

<ng-template #jsonExampleTmpl>
  <pre><code>{{ jsonExample | json }}</code></pre>
</ng-template>
