<div>
  <h3 class="strong">Available Data</h3>
  <p>Data points related to your previous selection:</p>
  <p class="strong">{{ pattern }}</p>
</div>
<div class="list">
  <button
    *ngFor="let element of data"
    class="data-btn mat-elevation-z3"
    (click)="dataClicked(element)"
  >
    {{ element }}
  </button>
</div>
