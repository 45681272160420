import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, combineLatest, merge, of } from 'rxjs';
import { first, startWith, switchMap, tap } from 'rxjs/operators';

import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { SiteTag } from 'src/models/device.models';

@Component({
  selector: 'app-site-filter',
  templateUrl: './site-filter.component.html',
})
export class SiteFilterComponent implements OnInit {
  @Input() sites: SiteTag[];
  @Output() sitesFiltered = new EventEmitter<SiteTag[]>();
  form: UntypedFormGroup;

  organizations$ = this.data.organizations$;
  countries$ = this.data.countries$;

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
  ) {
    this.loadData();
  }

  ngOnInit(): void {
    this.form = this.buildFilterForm();
    this.handleInputs.subscribe();
  }

  private loadData() {
    combineLatest([
      this.data.organizations$.pipe(
        switchMap((organizations) =>
          organizations
            ? of(organizations)
            : this.dataLoader.loadOrganizations(),
        ),
      ),
      this.data.countries$.pipe(
        switchMap((countries) =>
          countries ? of(countries) : this.dataLoader.loadCountries(),
        ),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  private get handleInputs(): Observable<unknown> {
    return merge(
      this.form.valueChanges,
      this.data.sites$.pipe(
        tap((sites) => {
          this.sites = sites ? sites : [];
        }),
      ),
    ).pipe(
      startWith(this.form.value),
      tap(() => {
        this.emitFilteredSites();
      }),
    );
  }

  private emitFilteredSites() {
    let result = this.sites;
    result = this.searchFilter(result);
    result = this.countryFilter(result);
    result = this.organizationFilter(result);

    this.sitesFiltered.emit(result);
  }

  private countryFilter(sites: SiteTag[]): SiteTag[] {
    const keyword = this.form.value.country;
    return !keyword
      ? sites
      : sites.filter((site) => site.metadata.country.includes(keyword));
  }

  private organizationFilter(sites: SiteTag[]): SiteTag[] {
    const keyword = this.form.value.organization;
    return !keyword
      ? sites
      : sites.filter((site) => site.metadata.organization.includes(keyword));
  }

  private searchFilter(sites: SiteTag[]): SiteTag[] {
    const keyword = this.form.value.search.toLowerCase();
    return !keyword
      ? sites
      : sites.filter(
          (site) =>
            site.metadata.infra_Site.toLowerCase().includes(keyword) ||
            site.metadata.site_long_name.toLowerCase().includes(keyword),
        );
  }

  private buildFilterForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      search: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      organization: new UntypedFormControl(''),
    });
  }
}
