import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Source } from 'src/models/device-type.models';

@Component({
  selector: 'app-parser-type-selection',
  templateUrl: './parser-type-selection.component.html',
  styleUrls: ['./parser-type-selection.component.scss'],
})
export class ParserTypeSelectionComponent {
  @Input() source: Source;
  @Output() selectionChanged = new EventEmitter<string>();

  constructor() {}

  public parserTypeSelected(type: string) {
    this.selectionChanged.emit(type);
  }
}
