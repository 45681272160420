import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss'],
})
export class NewFolderDialogComponent {
  nameForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
  });

  constructor(public dialogRef: MatDialogRef<NewFolderDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(this.nameForm.value.name);
  }
}
