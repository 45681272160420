<div class="input-area">
  <textarea [formControl]="input" class="mat-typography"></textarea>
  <button
    mat-icon-button
    class="clear-input-btn"
    *ngIf="input.value.length"
    (click)="clearInput()"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div class="feedback-box">
  <div class="feedback" *ngIf="!isValidJson && input.value.length">
    <p class="feedback-text">Looks like your input is not valid JSON!</p>
    <mat-icon class="feedback-icon">error_outline</mat-icon>
  </div>
  <div class="feedback" *ngIf="!input.value.length">
    <p class="feedback-text">Please paste JSON to parse attributes!</p>
    <mat-icon class="feedback-icon">input</mat-icon>
  </div>
  <div class="feedback" *ngIf="isValidJson && input.value">
    <p class="feedback-text">Your JSON has been parsed</p>
    <mat-icon class="feedback-icon">done</mat-icon>
  </div>
</div>
