import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RouteDto } from 'src/models/data-routing.models';

@Component({
  selector: 'app-routing-list-element',
  templateUrl: './routing-list-element.component.html',
  styleUrls: ['./routing-list-element.component.scss'],
})
export class RoutingListElementComponent {
  @Input() routing: RouteDto;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public openDetails(id: string): void {
    this.router.navigate(['details', encodeURIComponent(id)], {
      relativeTo: this.route,
    });
  }
}
