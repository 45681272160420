import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { combineLatest } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first, switchMap } from 'rxjs/operators';
import { InterfaceCreationComponent } from 'src/app/pages/main/interface-management/interface-creation/interface-creation.component';
import { routingDestinationTypes } from 'src/app/pages/main/routing-list/routing-creation/routing-creation.config';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { Interface } from 'src/models/data-routing.models';
import { SiteTag } from 'src/models/device.models';
import { AuthService } from '../../../services/state/auth/auth.service';
import { DataLoaderService } from '../../../services/state/data/data-loader.service';

@Component({
  selector: 'app-interface-management',
  templateUrl: './interface-management.component.html',
})
export class InterfaceManagementComponent implements OnInit {
  screensizeIndex$ = this.interfaceState.breakpointIndex$;
  filteredInterfaces$ = this.data.interfaces$;
  interfaces$ = this.data.interfaces$;
  sites$ = this.data.sites$;
  sites: SiteTag[] = [];

  constructor(
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
    private dataLoader: DataLoaderService,
    public auth: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.data.interfaces$.pipe(
        switchMap((interfaces) =>
          interfaces ? of(interfaces) : this.dataLoader.loadInterfaces(),
        ),
      ),
      this.data.sites$.pipe(
        switchMap((sites) => (sites ? of(sites) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe((data) => {
        this.sites = data[1];
      });
  }

  public updateFilteredInterfaces(interfaces: Interface[]): void {
    this.filteredInterfaces$ = of(
      interfaces.sort((a, b) =>
        a.metadata.name?.localeCompare(b.metadata.name),
      ),
    );
  }

  public editInterface(interf: Interface): void {
    this.dialog.open(InterfaceCreationComponent, { data: interf });
  }

  public createInterface() {
    this.dialog.open(InterfaceCreationComponent, {});
  }

  public getSiteName(tag_id: string): string {
    const site = this.sites.find((site) => site.tag_id === tag_id);
    return site
      ? `${site.metadata.iso_code} - ${site.metadata.site_long_name}`
      : tag_id;
  }

  public getDestinationName(destinationId: string): string {
    const routingDestinationType = routingDestinationTypes.find(
      (routingDestinationType) => routingDestinationType.id === destinationId,
    );
    return routingDestinationType?.label ?? destinationId;
  }
}
