import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { RouteDto } from 'src/models/data-routing.models';
import { DataLoaderService } from '../../../services/state/data/data-loader.service';

@Component({
  selector: 'app-routing-list',
  templateUrl: './routing-list.component.html',
  styleUrls: ['./routing-list.component.scss'],
})
export class RoutingListComponent {
  routings$ = this.data.routings$;
  sites$ = this.data.sites$;
  screensizeIndex$ = this.interfaceState.breakpointIndex$;
  creationParams = { step: 1 };
  filteredRoutings$ = this.data.routings$;
  tileView: boolean;

  constructor(
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
    private dataLoader: DataLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
  ) {
    this._loadData();
  }

  routingLoader(routings) {
    return routings !== undefined;
  }

  private _loadData() {
    combineLatest([
      this.data.routings$.pipe(
        switchMap((routings) =>
          routings ? of(routings) : this.dataLoader.loadRoutings(),
        ),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  public updateFilteredRoutings(routings: RouteDto[]): void {
    this.filteredRoutings$ = of(routings);
  }

  public setViewSelection(tileView: boolean): void {
    this.tileView = tileView;
  }

  public openDetails(id: string): void {
    this.router.navigate(['details', encodeURIComponent(id)], {
      relativeTo: this.route,
    });
  }
}
