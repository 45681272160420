import { Component, Input } from '@angular/core';
import { HierarchyNode } from 'src/models/device-hierarchy.models';

@Component({
  selector: 'app-folder-list-element',
  templateUrl: './folder-list-element.component.html',
  styleUrls: ['./folder-list-element.component.scss'],
})
export class FolderListElementComponent {
  @Input() node: HierarchyNode;

  constructor() {}

  public get deviceCount(): number {
    return this.node.children.filter((node) => node.isDevice).length;
  }
}
