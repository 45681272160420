<div class="parser-selection">
  <h4>Choose a parser type:</h4>
  <div class="parser-list">
    <div
      class="parser-list-element"
      *ngIf="source.source_id === 'firefly'"
      (click)="parserTypeSelected('multi')"
    >
      <h5 class="parser-list-element-title">Multi Parser</h5>
      <p>Parser for devices with multipe payload types</p>
    </div>
    <div
      class="parser-list-element"
      *ngIf="source.source_id === 'firefly'"
      (click)="parserTypeSelected('single')"
    >
      <h5 class="parser-list-element-title">Single Parser</h5>
      <p>Simple parser for devices with one payload type</p>
    </div>
    <div
      class="parser-list-element"
      *ngIf="source.source_id === 'iot_core'"
      (click)="parserTypeSelected('json')"
    >
      <h5 class="parser-list-element-title">JSON Mapping</h5>
      <p>JSON parser with possibility to rename fields</p>
    </div>
    <div
      class="parser-list-element"
      *ngIf="source.source_id === 'iot_core'"
      (click)="parserTypeSelected('passthrough')"
    >
      <h5 class="parser-list-element-title">JSON Passthrough</h5>
      <p>JSON parser that transferes the payload as received</p>
    </div>
  </div>
</div>
