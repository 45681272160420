<div class="management-forms">
  <ng-container *appLoader="sites$">
    <div class="content" [formGroup]="form" *ngIf="form">
      <h3 class="blue-text">User Information</h3>

      <mat-form-field>
        <mat-label>Contact Email</mat-label>
        <input matInput formControlName="email" *ngIf="!editMode" />
        <mat-error *ngIf="form.get('email')!.hasError('pattern')">
          Has to be a valid Email address on a "solvay.com" or "syensqo.com"
          domain
        </mat-error>
        <input matInput [value]="form.value.email" disabled *ngIf="editMode" />
      </mat-form-field>

      <h3 class="blue-text">
        Site Access
        <button
          mat-icon-button
          color="primary"
          class="add-btn"
          (click)="toggleHelp()"
        >
          <mat-icon>help</mat-icon>
        </button>
      </h3>

      <mat-checkbox
        color="primary"
        formControlName="administrator"
        *ngIf="auth.isAdmin()"
      >
        Administrator
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        formControlName="technical_administrator"
        *ngIf="auth.isTechnicalAdmin()"
      >
        Technical Administrator
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        formControlName="active"
        *ngIf="auth.isAdmin()"
      >
        Active
      </mat-checkbox>
      <ng-container *ngFor="let site of accessForm.controls; let i = index">
        <div class="accessform-row">
          <ng-container [formGroup]="site">
            <mat-form-field>
              <mat-label>Site</mat-label>
              <mat-select formControlName="site">
                <mat-option
                  *ngFor="let site of unchosenSites$(i) | async"
                  [value]="site.tag_id"
                >
                  {{ site?.metadata?.iso_code }} -
                  {{ site?.metadata?.site_long_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Access</mat-label>
              <mat-select formControlName="level">
                <mat-option
                  *ngFor="let level of accessLevels"
                  [value]="level.level"
                >
                  {{ level.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <button mat-icon-button (click)="removeAccessFromFields(i)">
            <mat-icon color="primary">delete</mat-icon>
          </button>
          <button mat-icon-button *ngIf="i === 0" class="invisible"></button>
        </div>
      </ng-container>
      <button
        mat-button
        (click)="addAccessFormFields()"
        class="add-btn blue-btn"
        *ngIf="auth.isMaintainer()"
      >
        +
      </button>
      <div class="actions">
        <button mat-button (click)="cancel()" class="blue-btn">Cancel</button>
        <button
          mat-button
          (click)="submit()"
          class="blue-btn"
          [disabled]="!this.form.valid"
          *ngIf="auth.isMaintainer()"
        >
          Submit
        </button>
      </div>
    </div>

    <div class="help-container" *ngIf="displayHelp">
      <p>Administrator: Has read and write access to all sites.</p>
      <p>
        Maintainer: Has read and write access to one or more specified sites.
      </p>
      <p>Operator: Has only read access to one or more specified sites.</p>
      <p>
        Active: A user can be deactivated by admins. This will not delete the
        user but block access to the platform.
      </p>
    </div>

    <button
      mat-fab
      class="floating-button"
      color="warn"
      (click)="delete(deleteUserTmpl)"
      *ngIf="auth.isTechnicalAdmin() && editMode"
      matTooltipPosition="below"
      matTooltip="delete user"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
</div>

<ng-template #deleteUserTmpl>
  <h1 mat-dialog-title>Confirm Delete Resort</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to delete this?</p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button [mat-dialog-close]="false" class="dialog-btn">
      Close
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="dialog-btn"
      color="warn"
    >
      Yes
    </button>
  </div>
</ng-template>
