import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { WizardStep } from 'src/models/app-routing.models';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss'],
})
export class FormStepperComponent {
  @Input() activeStep: WizardStep;
  @Input() stepValid: boolean;
  @Output() submitted = new EventEmitter();
  constructor(private router: Router) {}

  navigate(index: number): void {
    this.router.navigate([], {
      queryParams: { step: index },
      queryParamsHandling: 'merge',
    });
  }
}
