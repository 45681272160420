import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, merge, of, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { DeviceFilter } from 'src/models/device.models';

const GATEWAY_POLLING_TIME = 600000; // 10min

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public screensizeIndex$ = this.interfaceState.breakpointIndex$;
  public monitoringStats$ = this.data.deviceMonitoring$;
  public gatewayStats$ = this.data.gatewayStatistics$;
  public displayedDevices$ = this.data.devices$;
  public models$ = this.data.deviceTypes$;
  public brands$ = this.data.brands$;
  public sites$ = this.data.sites$;
  public sources$ = this.data.sources$;
  public filterForm: UntypedFormGroup;
  public filter: DeviceFilter = {
    project_id: '',
    brand_id: '',
    device_type_id: '',
    source: '',
    status: '',
  };

  public isLoadingGateways$ = new BehaviorSubject(true);

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private interfaceState: InterfaceStateService,
    public dialog: MatDialog,
  ) {
    this._loadData();
  }

  ngOnInit() {
    this.filterForm = this.interfaceState.dashboardFilter;

    this.filterForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => this.interfaceState.setDashboardFilter(this.filterForm)),
      )
      .subscribe((filter) => {
        this.filter = filter;
        this.dataLoader.loadDevicesFromLocalData(this.filter);
        this.dataLoader.loadDeviceMonitoringStatsFromLocal(this.filter);
      });

    timer(0, GATEWAY_POLLING_TIME)
      .pipe(
        untilDestroyed(this),
        tap(() => this.isLoadingGateways$.next(true)),
        switchMap(() => this.dataLoader.loadGateways()),
      )
      .subscribe();
  }

  public openMap(templateRef: TemplateRef<unknown>) {
    this.dialog.open(templateRef);
  }

  private _loadData() {
    if (this.interfaceState.dashboardFilter) {
      this.interfaceState.dashboardFilter.get('status')?.setValue('');
    }

    merge(
      this.dataLoader.loadDevices(this.interfaceState.dashboardFilter.value),
      this.dataLoader.loadDeviceMonitoringStats(
        this.interfaceState.dashboardFilter.value,
      ),
      this.data.deviceTypes$.pipe(
        untilDestroyed(this),
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
        catchError(() => of([])),
      ),
      this.data.brands$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
        catchError(() => of([])),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
        catchError(() => of([])),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
        catchError(() => of([])),
      ),
      this.data.gateways$.pipe(
        tap(() => this.isLoadingGateways$.next(false)),
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadGateways(),
        ),
        catchError(() => of([])),
      ),
    ).subscribe();
  }
}
