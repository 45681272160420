<div class="multi-parser">
  <div class="actions">
    <button
      mat-raised-button
      color="primary"
      (click)="openSingleEditorDialog('_switch')"
    >
      Edit switch parser
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="openSingleEditorDialog('_default')"
    >
      Edit default parser
    </button>
  </div>
  <mat-expansion-panel class="single-parser-panel" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Single parser list </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="single-parser-list">
      <div
        *ngFor="let sp of parserCreation.singleParserList._switch.calculations"
        (click)="openSingleEditorDialog(sp.target)"
        class="single-parser-item mat-elevation-z1"
      >
        <h2>{{ sp.target }}</h2>
        <h3>
          Condition:
          {{ sp.formula }}
        </h3>
      </div>
    </div>
  </mat-expansion-panel>
  <app-blueprint-test></app-blueprint-test>
</div>
