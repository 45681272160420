import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import match from 'mqtt-match';
import { Observable, filter, map, of } from 'rxjs';
import { RouteDto } from '../../../../../models/data-routing.models';
import { HierarchyNode } from '../../../../../models/device-hierarchy.models';
import { DataLoaderService } from '../../../../services/state/data/data-loader.service';
import { DataStoreService } from '../../../../services/state/data/data-store.service';
import { DeviceHierarchyStoreService } from '../../../../services/state/data/device-hierarchy/device-hierarchy-store.service';

@Component({
  templateUrl: './device-move-confirmation-dialog.component.html',
  styleUrls: ['./device-move-confirmation-dialog.component.scss'],
})
export class DeviceMoveConfirmationDialogComponent implements OnInit {
  public routesImpacted$: Observable<RouteDto[]>;
  public encodeURIComponent = encodeURIComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceMoveConfirmationData,
    private dataService: DataStoreService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private dataLoader: DataLoaderService,
  ) {}

  ngOnInit() {
    this.routesImpacted$ = this.getRoutesImpacted$();
  }

  private getRoutesImpacted$() {
    const sourcePath = this.deviceHierarchy.getPathForNode(
      this.data.originNode,
    );
    const destinationPath = this.deviceHierarchy.getPathForNode(
      this.data.destinationNode,
    );

    const routings$ = this.dataService.routings
      ? of(this.dataService.routings)
      : this.dataLoader.loadRoutings();

    return routings$.pipe(
      filter((routings) => !!routings),
      map((routings) =>
        routings!.filter((routing) =>
          this.isRoutingImpacted(
            routing.pattern,
            sourcePath,
            destinationPath,
            this.data.deviceIds,
          ),
        ),
      ),
    );
  }

  private isRoutingImpacted(
    routingPattern: string,
    oldPath: string,
    newPath: string,
    deviceIds: string[],
  ) {
    return deviceIds.some((deviceId) => {
      // transform /site_FR.TEST/deviceId/someVariable
      // into: /site_FR.TEST/deviceId
      // so routes targeting specific variables can also trigger the warning
      const routingPatternUntilDevice = this.removeVariablesFromPattern(
        routingPattern,
        deviceId,
      );
      return (
        match(routingPatternUntilDevice, `${oldPath}/${deviceId}`) &&
        !match(routingPatternUntilDevice, `${newPath}/${deviceId}`)
      );
    });
  }

  private removeVariablesFromPattern(routingPattern: string, deviceId: string) {
    const regex = new RegExp(`${deviceId}/.*$`);
    return routingPattern.replace(regex, deviceId);
  }
}

export interface DeviceMoveConfirmationData {
  originNode: HierarchyNode;
  destinationNode: HierarchyNode;
  deviceIds: string[];
}
