import { Component, Input, OnInit } from '@angular/core';
import {
  Calculation,
  DecoderType,
  DecodingConfiguration,
  DeviceTypeDto,
} from 'src/models/device-type.models';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SymbolNode, parse } from 'mathjs';
import { filter, take, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { ParserCreationService } from './parser-creation.service';

@Component({
  selector: 'app-parser-tab',
  templateUrl: './parser-tab.component.html',
  styleUrls: ['./parser-tab.component.scss'],
})
export class ParserTabComponent implements OnInit {
  @Input() model: DeviceTypeDto;

  constructor(
    public dialog: MatDialog,
    public parserCreation: ParserCreationService,
    public dataLoader: DataLoaderService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    if (this.model.decoder_blueprint) {
      this.parserCreation.setBlueprint(this.model.decoder_blueprint);
    }
  }

  get parserType() {
    const hexBP = this.parserCreation.hexBlueprint;
    const jsonBP = this.parserCreation.jsonBlueprint();
    if (!this.model.metadata.created_by_api) {
      return 'Built in';
    } else if (this.model.source.source_id === 'iot_core') {
      if (jsonBP && jsonBP.type === DecoderType.PASSTHROUGH) {
        return 'JSON Passthrough';
      } else if (jsonBP && jsonBP.type === DecoderType.JSON) {
        return 'JSON Mapping';
      } else {
        return undefined;
      }
    } else if (this.model.source.source_id === 'firefly') {
      if (hexBP && hexBP.type === DecoderType.MULTI) {
        return 'Multi Parser';
      } else if (hexBP && hexBP.type === DecoderType.SINGLE) {
        return 'Single Parser';
      } else {
        return undefined;
      }
    }
  }

  switchParserType(type: DecoderType) {
    this.parserCreation.setBlueprintType(type);
  }

  resetParserType(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          html: `You are about to switch parser type. The current parser configuration will be lost. Do you want to continue?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        tap(() => {
          this.parserCreation.setBlueprint();
        }),
      )
      .subscribe();
  }

  onSave() {
    const decodingInfo =
      this.model.source.source_id === 'firefly'
        ? this.parserCreation.hexBlueprint
        : this.parserCreation.jsonBlueprint(true);

    if (this.model.source.source_id === 'firefly') {
      const unknownVariables = this.getUnknownVariablesInBlueprint();
      if (unknownVariables.length > 0) {
        this.snackBar.open(
          `Found unknown variables in calculations: ${unknownVariables.join(', ')}`,
          'Close',
        );
        return;
      }
    }

    return this.dataLoader
      .updateDeviceTypeCodec(this.model.device_type_id, decodingInfo!)
      .pipe(
        take(1),
        tap((res) => (this.model = res)),
        tap(() => this.snackBar.open('Parser updated', 'Close')),
      )
      .subscribe();
  }

  private getUnknownVariablesInBlueprint(): string[] {
    return Object.values(this.parserCreation.hexBlueprint?.config ?? {})
      .map((parser) => this.getUnknownVariablesInParser(parser))
      .reduce((acc, curr) => acc.concat(curr), []); // for some reason we don't have access to .flat()
  }

  private getUnknownVariablesInParser(
    parser?: DecodingConfiguration,
  ): string[] {
    if (!parser) {
      return [];
    }
    const variableNames = Object.keys(parser.variables).concat('port');
    const unknownVariables: string[] = [];
    for (const calculation of parser.calculations) {
      unknownVariables.push(
        ...this.getUnknownVariablesInCalculation(calculation, variableNames),
      );
    }
    return unknownVariables;
  }

  private getUnknownVariablesInCalculation(
    calculation: Calculation,
    variableNames: string[],
  ): string[] {
    const mathNode = parse(calculation.formula);
    const calculationVariables: string[] = [];
    mathNode.traverse((node) => {
      if (node instanceof SymbolNode) {
        calculationVariables.push(node.name);
      }
    });
    return calculationVariables.filter(
      (calculationVariable) =>
        !variableNames.includes(calculationVariable) ||
        calculationVariable === calculation.target,
    );
  }
}
