import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
  @Output() addFolder = new EventEmitter();
  @Output() addDevice = new EventEmitter();
  @Output() addDevices = new EventEmitter();

  open = false;

  constructor() {}

  public toggleOpen() {
    this.open = !this.open;
  }
}
