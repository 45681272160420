import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { filter, first, tap } from 'rxjs/operators';
import { DecodingConfiguration } from 'src/models/device-type.models';
import { ParserCreationService } from '../../parser-creation.service';

@Component({
  selector: 'app-single-parser-dialog',
  templateUrl: './single-parser-dialog.component.html',
})
export class SingleParserDialogComponent {
  private modifiedConfig = this.data.config;
  configValid = true;

  constructor(
    public dialogRef: MatDialogRef<SingleParserDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string; config: DecodingConfiguration },
    public parserCreation: ParserCreationService,
  ) {
    dialogRef
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        tap(() =>
          this.parserCreation.setHexBluePrintConfig(
            this.data.id,
            this.modifiedConfig,
          ),
        ),
        first(),
      )
      .subscribe();
  }

  public configModified(config: DecodingConfiguration): void {
    this.modifiedConfig = config;
  }
}
