import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PubSubMessage } from 'src/models/data-routing.models';

@Component({
  selector: 'app-data-preview',
  templateUrl: './data-preview.component.html',
  styleUrls: ['./data-preview.component.scss'],
})
export class DataPreviewComponent implements OnChanges {
  @Input() tableData: PubSubMessage[] = [];
  @Input() columnsToDisplay: string[];
  dataSource = new MatTableDataSource<PubSubMessage>(this.tableData);

  @Output() rowSelect = new EventEmitter();

  selectedRow: PubSubMessage;

  constructor() {}

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(
      [...this.tableData].sort((a, b) =>
        a._received_timestamp < b._received_timestamp ? 1 : -1,
      ),
    );
  }

  public isTimestamp(key: string): boolean {
    return key.includes('timestamp');
  }
}
