<div class="list-management-filter">
  <form [formGroup]="form" *ngIf="form" class="filter">
    <mat-form-field class="filter-field">
      <mat-label>Search</mat-label>
      <input matInput formControlName="search" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-form-field class="filter-field">
      <mat-label>Country</mat-label>
      <mat-select formControlName="country">
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let country of countries$ | async"
          [value]="country.name"
        >
          {{ country.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filter-field">
      <mat-label>Organization</mat-label>
      <mat-select formControlName="organization">
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let organization of organizations$ | async"
          [value]="organization.name"
        >
          {{ organization.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
