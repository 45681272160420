import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ParserCreationService } from '../parser-creation.service';

@Component({
  selector: 'app-blueprint-test',
  templateUrl: './blueprint-test.component.html',
  styleUrls: ['./blueprint-test.component.scss'],
})
export class BlueprintTestComponent {
  form = new FormGroup({
    payload: new FormControl('', [
      Validators.required,
      Validators.pattern(/[0-9A-F]+$/),
    ]),
    port: new FormControl(10, [Validators.pattern(/[0-9]+$/)]),
  });
  decodedPayload: { statusCode: number };

  constructor(public parserCreation: ParserCreationService) {}

  testBlueprint() {
    this.parserCreation
      .decodeTestPayload(this.form.value.payload!, this.form.value.port!)
      .subscribe((res) => (this.decodedPayload = res));
  }

  get resultPreview() {
    if (this.decodedPayload.statusCode === 500) {
      return {
        statusCode: 500,
        message:
          'The server responded with a status code 500 (internal server error). There seems to be something wrong with your decoding configuration.',
      };
    } else {
      return this.decodedPayload;
    }
  }
}
