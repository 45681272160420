<div class="btn-container">
  <button mat-fab class="main-btn" (click)="toggleOpen()">
    <mat-icon class="plus" [ngClass]="{ rotate: open }">add</mat-icon>
  </button>
  <button
    mat-mini-fab
    class="action-btn"
    [ngClass]="{ 'devices-btn': open }"
    (click)="addDevices.emit()"
    matTooltipPosition="before"
    matTooltip="add multiple devices"
  >
    <mat-icon svgIcon="custom-add-devices" class="icon"></mat-icon>
  </button>
  <button
    mat-mini-fab
    class="action-btn"
    [ngClass]="{ 'device-btn': open }"
    (click)="addDevice.emit()"
    matTooltipPosition="before"
    matTooltip="add a single device"
  >
    <mat-icon svgIcon="custom-add-device" class="icon"></mat-icon>
  </button>
  <button
    mat-mini-fab
    class="action-btn"
    [ngClass]="{ 'folder-btn': open }"
    (click)="addFolder.emit()"
    matTooltipPosition="before"
    matTooltip="new folder"
  >
    <mat-icon svgIcon="custom-add-folder" class="icon"></mat-icon>
  </button>
</div>
