import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { DataLoaderService } from '../data/data-loader.service';
import { DataStoreService } from '../data/data-store.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EditRoutingGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const routeId = route.paramMap.get('id');

    if (routeId === null) return of(false);

    return this.data.routing$.pipe(
      switchMap((routing) =>
        routing
          ? of(routing)
          : this.dataLoader.loadRouting(decodeURIComponent(routeId)),
      ),
      map(
        (routing) =>
          routing !== undefined &&
          !routing?.is_locked &&
          !!this.auth.isMaintainerOnRoute(routing.id),
      ),
      catchError(() => of(false)),
    );
  }
}
