import { Component } from '@angular/core';

@Component({
  selector: 'app-mqtt-topic-help-dialog',
  templateUrl: './mqtt-topic-help-dialog.component.html',
  styleUrls: ['./mqtt-topic-help-dialog.component.scss'],
})
export class MQTTTopicHelpDialogComponent {
  constructor() {}
}
