import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DeviceCreationService } from './device-creation.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceTypeSelectedGuard implements CanActivate {
  constructor(
    private router: Router,
    private deviceCreation: DeviceCreationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const typeSelected = this.deviceCreation.deviceTypeSelected;
    if (!typeSelected) {
      this.router.navigate(['/home/device/new'], {
        queryParams: { path: route.queryParams.path },
      });
    }
    return typeSelected;
  }
}
