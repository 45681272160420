<div class="list-header">
  <div class="list-header-top">
    <h4 class="list-title">Listing Devices: {{ routedDevices.length }}</h4>
    <ng-container>
      <mat-form-field name="filter" class="list-filter">
        <mat-label>Filter devices</mat-label>
        <input matInput [formControl]="filter" />
      </mat-form-field>
    </ng-container>
  </div>
  <mat-checkbox [formControl]="allSelected" color="primary"
    >Toggle all devices at once</mat-checkbox
  >
</div>
<div class="list">
  <div
    class="list-element mat-elevation-z4"
    [ngClass]="{ selected: isDeviceSelected(device.device_id) }"
    *ngFor="let device of filteredDevices"
    (click)="
      isDeviceSelected(device.device_id)
        ? removeDeviceFromSelection(device)
        : addDeviceToSelection(device)
    "
  >
    <div class="list-element-body">
      <p>{{ device.device_name }}</p>
      <p>{{ device.hierarchy_prefix }}</p>
    </div>
    <div class="list-element-footer">
      <mat-icon *ngIf="!isDeviceSelected(device.device_id)"></mat-icon>
      <mat-icon *ngIf="isDeviceSelected(device.device_id)">visibility</mat-icon>
      <p>{{ device.data.length }} Data</p>
    </div>
  </div>
</div>
