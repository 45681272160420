import { Component, Input } from '@angular/core';
import Chart from 'chart.js';
import { DeviceMonitoringStats } from 'src/models/device.models';

@Component({
  selector: 'app-device-status-chart',
  templateUrl: './device-status-chart.component.html',
  styleUrls: ['./device-status-chart.component.scss'],
})
export class DeviceStatusChartComponent {
  @Input() set statistics(stats: DeviceMonitoringStats) {
    if (stats) {
      this.formatGraphData(stats);
    }
  }
  public chart: Chart;
  public colors = [
    { name: 'Battery and Connection', value: '#f44336' },
    { name: 'No Warning', value: '#4caf50' },
    { name: 'Battery', value: '#ff9800' },
    { name: 'Connection', value: '#FDD835' },
    { name: 'Unknown', value: '#cfcfcf' },
  ];

  constructor() {}

  formatGraphData(stats: DeviceMonitoringStats) {
    const data = {
      datasets: [
        {
          data: [
            stats.devices_with_no_warning,
            stats.devices_with_last_uplink_error,
            stats.devices_with_low_battery,
            stats.devices_with_low_battery_last_uplink_error,
            stats.devices_with_no_message_yet,
          ],
          backgroundColor: [
            '#4caf50',
            '#ff9800',
            '#FDD835',
            '#f44336',
            '#cfcfcf',
          ],
        },
      ],
    };

    const options = {
      responsive: true,
      aspectRatio: 1.2,
      tooltips: { enabled: false },
      animation: false,
      showTooltips: false,
    };

    Object.keys(Chart.instances).forEach((key) => {
      const loInst = Chart.instances[key];
      if (loInst.canvas.id === 'chart') {
        loInst.destroy();
      }
    });

    this.chart = new Chart(document.getElementById('chart'), {
      type: 'doughnut',
      data: data,
      options: options,
    });
  }
}
