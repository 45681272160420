<h1 mat-dialog-title>Device characterization</h1>

<mat-dialog-content>
  <p>
    Use this menu to set the characterization used by the device. <br />
    Start by choosing a device data, then select a characterization function,
    and fill in the variable values.
  </p>

  <div class="forms">
    <div
      class="form"
      *ngFor="let form of calibrationForms.controls; let i = index"
      [formGroup]="form"
    >
      <mat-form-field>
        <mat-label>Datafield</mat-label>
        <mat-select formControlName="field">
          <mat-option *ngFor="let field of data.fields" [value]="field">
            {{ field }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>characterization function</mat-label>
        <mat-select
          (selectionChange)="setArgsOnForm(form, $event.value)"
          formControlName="formula"
        >
          <mat-option *ngFor="let fn of functions" [value]="fn">
            <mat-option>{{ fn.formula }}</mat-option>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="form.get('args')" formGroupName="args">
        <ng-container *ngFor="let controlName of form.get('args').controls">
          <mat-form-field>
            <mat-label>Variable "{{ controlName }}"</mat-label>
            <input type="number" matInput [formControlName]="controlName" />
          </mat-form-field>
        </ng-container>
      </ng-container>

      <span class="flexer"></span>

      <button
        type="button"
        mat-icon-button
        color="warn"
        (click)="deleteCalibration(i)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    color="primary"
    (click)="calibrationForms.push(createCalibrationForm())"
  >
    Add characterization
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!this.calibrationForms.valid && this.calibrationForms.touched"
    (click)="submit()"
  >
    Set characterizations
  </button>
  <mat-error *ngIf="calibrationForms.hasError('uniqueKeys')">
    Do not characterize the same data more than once
  </mat-error>
</mat-dialog-actions>
