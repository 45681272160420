import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GatewayStatusChartComponent } from './gateway-status-chart/gateway-status-chart.component';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SharedModule } from 'src/app/services/shared.module';

import { DashboardComponent } from './dashboard.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { DeviceStatusChartComponent } from './device-status-chart/device-status-chart.component';

const COMPONENTS = [
  DeviceListComponent,
  DashboardComponent,
  DeviceStatusChartComponent,
  GatewayStatusChartComponent,
];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    LeafletModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class DashboardModule {}
