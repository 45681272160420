<h2 *ngIf="!brand" mat-dialog-title>Create a new brand</h2>
<h2 *ngIf="brand" mat-dialog-title>Edit brand {{ brand.brand_id }}</h2>
<div mat-dialog-content>
  <div class="form-wrapper" [formGroup]="brandForm">
    <div class="centered-content">
      <app-image-upload
        class="image"
        [img]="imageFile.value"
        [imgPreviewUrl]="imagePreviewUrl"
        (imgSelected)="imageFile.setValue($event)"
      ></app-image-upload>
    </div>
    <mat-form-field appearance="outline" *ngIf="!brand">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="brandForm.get('name')?.hasError('required')"
        >This field is required!</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        rows="4"
        style="resize: none"
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    color="warn"
    (click)="delete(deleteBrandTmpl)"
    *ngIf="brand"
  >
    Delete
  </button>
  <div class="spacer"></div>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    (click)="confirm()"
    cdkFocusInitial
    [disabled]="brandForm.invalid"
  >
    Confirm
  </button>
</div>

<ng-template #deleteBrandTmpl>
  <h1 mat-dialog-title>Confirm brand deletion</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to delete this?</p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button [mat-dialog-close]="false" class="dialog-btn">
      Close
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="dialog-btn"
      color="warn"
    >
      Yes
    </button>
  </div>
</ng-template>
