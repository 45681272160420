import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/services/state/auth/auth.service';

export const techAdminGuard: CanActivateFn = () => {
  return (
    inject(AuthService).isTechnicalAdmin() ||
    inject(Router).createUrlTree(['/home'])
  );
};
