import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    LayoutModule,
    MatListModule,
    MatRadioModule,
    MatTreeModule,
    MatMenuModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
  ],
  exports: [
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    LayoutModule,
    MatListModule,
    MatRadioModule,
    MatTreeModule,
    MatMenuModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
  ],
})
export class MaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'custom-solvay-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/logo-solvay-white.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-routing-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/route_active.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-routing-paused',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/route_paused.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/info.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-infos',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/Infos.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-catalogue',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/catalogue.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-add-folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/add-folder.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-add-device',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/add-device.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-add-devices',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/add-devices.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-route-btn',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/route-btn.svg',
      ),
    );
  }
}
