import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { DeviceDto, Downlink } from 'src/models/device.models';
import { DataLoaderService } from '../../../../../services/state/data/data-loader.service';
import { DataStoreService } from '../../../../../services/state/data/data-store.service';
import { SendDownlinkDialogComponent } from '../../send-downlink-dialog/send-downlink-dialog.component';

@Component({
  selector: 'app-detail-stepper',
  templateUrl: './detail-stepper.component.html',
  styleUrls: ['./detail-stepper.component.scss'],
})
export class DetailStepperComponent implements OnInit {
  @Input() device: DeviceDto;

  public downlinks$: Observable<Downlink[] | undefined>;
  public downlinkHistoryColumns = ['date', 'payload', 'port', 'action'];

  constructor(
    private dataStoreService: DataStoreService,
    private dataLoaderService: DataLoaderService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataLoaderService
      .loadDeviceDownlinks(this.device.device_id)
      .subscribe();

    this.downlinks$ = this.dataStoreService.deviceDownlinks$;
  }

  public getSortedObjectKeys(obj: object): string[] {
    return Object.keys(obj)
      .filter((key) => !key.startsWith('_'))
      .sort((a: string, b: string) => a.localeCompare(b));
  }

  public isIOTcoreDevice(deviceSource: string): boolean {
    return deviceSource.toLowerCase() === 'iot_core';
  }

  public isTimeStamp(key: string): boolean {
    return key === 'created_at' || key === 'updated_at';
  }

  public replaceUnderScore(key: string): string {
    return key.replace('_', ' ');
  }

  public onSendDownlink(downlink: Downlink) {
    const { device_id, source_id } = this.device;
    this.dialog.open(SendDownlinkDialogComponent, {
      data: { deviceId: device_id, sourceId: source_id, downlink: downlink },
    });
  }

  public timestampToDate(timestamp: number) {
    return new Date(timestamp);
  }

  canSendDownlink(): boolean {
    return (
      this.device.source_id === 'firefly' ||
      this.device.source_id === 'iot_core'
    );
  }
}
