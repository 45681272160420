import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceAlarm, DeviceDto, SiteTag } from 'src/models/device.models';
import { User } from 'src/models/user.models';
import { AlertingService } from './alerting.service';

@Component({
  selector: 'app-alerting',
  templateUrl: './alerting.component.html',
  styleUrls: ['./alerting.component.scss'],
})
export class AlertingComponent implements OnInit {
  public sites$ = this.data.sites$;
  public models$ = this.data.deviceTypes$;
  public brands$ = this.data.brands$;
  public sources$ = this.data.sources$;
  public siteSelection = new UntypedFormControl(this.alerting.selectedSite);
  public userEmailSearch = new UntypedFormControl('');
  public deviceFilterForm: UntypedFormGroup;
  public devices: DeviceDto[];
  public users: User[];

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    public alerting: AlertingService,
    private auth: AuthService,
  ) {
    this._loadData();
  }

  ngOnInit(): void {
    combineLatest([
      this.data.devices$,
      this.data.users$,
      this.siteSelection.valueChanges.pipe(
        startWith(this.siteSelection.value),
        tap(() => this.resetFilters()),
      ),
    ])
      .pipe(
        tap(([_devices, _users, site]) => {
          this.alerting.setSelectedSite(site);
          this.devices = this.getDevicesForSite(site);
          this.users = this.getUsersForSite(site);
        }),
      )
      .subscribe();

    this.deviceFilterForm = this.alerting.deviceFilter;

    this.deviceFilterForm.valueChanges
      .pipe(
        tap(() => this.alerting.setdeviceFilter(this.deviceFilterForm)),
        switchMap((filter) => this.dataLoader.loadDevices(filter)),
        tap(
          () =>
            (this.devices = this.getDevicesForSite(this.siteSelection.value)),
        ),
      )
      .subscribe();

    this.userEmailSearch.valueChanges
      .pipe(
        map((searchValue: string) =>
          this.getUsersForSite(this.siteSelection.value).filter((user) =>
            user.user_email.includes(searchValue),
          ),
        ),
        tap((users) => (this.users = users)),
      )
      .subscribe();
  }

  private _loadData() {
    combineLatest([
      this.dataLoader.loadDevices(),
      this.data.deviceTypes$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
        catchError(() => of([])),
      ),
      this.data.brands$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
        catchError(() => of([])),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
        catchError(() => of([])),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
        catchError(() => of([])),
      ),
      this.data.users$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadUsers())),
        catchError(() => of([])),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  private getDevicesForSite(site: string): DeviceDto[] {
    return this.data.devices
      ? this.data.devices.filter(
          (device) =>
            !!device?.tags.filter((tag) => tag.tag_id === site).length,
        )
      : [];
  }

  private getUsersForSite(site: string): User[] {
    return this.data.users.filter((user) =>
      Object.keys(user.roles).includes(site),
    );
  }

  private resetFilters(): void {
    this.alerting.resetdeviceFilter();
    if (this.deviceFilterForm)
      this.deviceFilterForm.patchValue(this.alerting.deviceFilter.value);
    this.userEmailSearch.setValue('');
  }

  public get showSiteSelectionPrompt(): boolean {
    return !(
      !this.alerting.selectedSite && this.auth.userHasMultipleSiteAccess
    );
  }

  public toggleConnectionAlert(device: DeviceDto): void {
    const alarm = device.alarm;
    alarm.connection.enable = !alarm.connection.enable;
    this.updateDeviceAlarm(device.device_id, alarm);
  }

  public toggleBatteryAlert(device: DeviceDto): void {
    const alarm = device.alarm;
    alarm.battery.enable = !alarm.battery.enable;
    this.updateDeviceAlarm(device.device_id, alarm);
  }

  public updateConnectionAlertDelay(event: Event, device: DeviceDto): void {
    const alarm = device.alarm;
    const target = event.target as HTMLInputElement;
    alarm.connection.delay = parseInt(target.value);
    this.updateDeviceAlarm(device.device_id, alarm);
  }

  public updateDeviceAlarm(deviceId: string, alarmInfo: DeviceAlarm): void {
    this.dataLoader.updateDeviceAlarm(deviceId, alarmInfo).subscribe();
  }

  public toggleUserAlert(user: User): void {
    const site = this.siteSelection.value;
    const alertInfo = { enable: !user.alerts[site] };
    this.dataLoader
      .updateUserAlert(user.user_email, alertInfo, site)
      .subscribe();
  }

  public get userHasUpdateRight() {
    const site = this.siteSelection.value;
    return this.auth.isAdmin || this.auth.isMaintainerOnSite(site);
  }

  public userAlertForSite(user: User) {
    const site = this.siteSelection.value;
    return user.alerts[site];
  }

  public setDevicePanelOpenState(state: boolean): void {
    this.alerting.setDevicesOpen(state);
  }

  public setUserPanelOpenState(state: boolean): void {
    this.alerting.setUserOpen(state);
  }

  public get site(): SiteTag {
    const site = this.data.sites?.find(
      (site) => site.tag_id === this.alerting.selectedSite,
    );
    return site as SiteTag;
  }

  public toggleSiteAlert() {
    const site = this.site;
    const alert = this.site.alert;
    const siteAlertConf = {
      cron: '0 12 * * *',
      enable: !alert.enable,
      timezone: 'Europe/Paris',
    };
    this.dataLoader.updateSiteAlert(site.tag_id, siteAlertConf).subscribe();
  }
}
