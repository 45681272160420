<app-form-stepper
  [activeStep]="activeStepConfig$ | async"
  [stepValid]="stepValidator()"
  (submitted)="submitForm()"
>
  <div *ngIf="activeStepIndex === 1" class="step">
    <ng-template *ngTemplateOutlet="general"></ng-template>
  </div>
  <div *ngIf="activeStepIndex === 2" class="step">
    <ng-template *ngTemplateOutlet="technical"></ng-template>
  </div>
  <div *ngIf="activeStepIndex === 3" class="step">
    <ng-template *ngTemplateOutlet="position"></ng-template>
  </div>
  <div *ngIf="activeStepIndex === 4" class="step">
    <ng-template *ngTemplateOutlet="metadata"></ng-template>
  </div>
</app-form-stepper>

<ng-template #general>
  <div class="step-layout">
    <app-device-type-banner [deviceType]="deviceType"></app-device-type-banner>
    <form class="input-area" [formGroup]="form">
      <ng-container formGroupName="general">
        <div class="path-container">
          <label for="path" class="path-label">Device will be added to</label>
          <p class="path" id="path">{{ deviceManagement.path$ | async }}</p>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Device Name</mat-label>
          <input matInput formControlName="name" #name autoFocus required />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          hintLabel="Allowed special Characters: - . _ + % ~"
        >
          <mat-label>Device ID</mat-label>
          <input #devEui matInput formControlName="id" required />
          <mat-error *ngIf="generalForm.get('id')!.hasError('pattern')">
            ID has to start and end with a lowercase letter and can only contain
            following special Characters: - . _ + % ~
          </mat-error>
          <mat-error
            *ngIf="generalForm.get('id').hasError('deviceAlreadyExists')"
          >
            A device with this id already exists
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Device Region</mat-label>
          <mat-select
            formControlName="deviceRegion"
            required
            (selectionChange)="updateConfigInfo($event.value)"
          >
            <mat-option value="EU">Europe</mat-option>
            <mat-option value="ASIA">Asia</mat-option>
            <mat-option value="US">United States</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-card>
          <mat-card-subtitle
            >You need to configure your device with these
            information:</mat-card-subtitle
          >
          <!-- <p>You need to configure your device with this info</p> -->
          <mat-card-content>
            <p>
              Project ID: <strong>{{ deviceConfig.project }}</strong>
            </p>
            <p>
              Region ID: <strong>{{ deviceConfig.region }}</strong>
            </p>
            <p>
              Registry ID: <strong>{{ deviceConfig.registry }}</strong>
            </p>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-container
        *ngIf="
          (site$ | async)?.metadata?.userAccessFlags && !!userAccessFlags.length
        "
      >
        <h3>
          Control Groups
          <mat-icon
            matTooltip="
            Export Control groups can be selected to prevent the diffusion of data to people outside of these controls groups. 
            Only the control group you have access to are visible here."
          >
            help_outline
          </mat-icon>
        </h3>
        <div>
          <mat-checkbox
            *ngFor="let flag of userAccessFlags; let i = index"
            class="checkbox"
            color="primary"
            (change)="onFlagChange($event.checked, flag)"
          >
            {{ flag }}
          </mat-checkbox>
        </div>
      </ng-container>
    </form>
  </div>
</ng-template>

<ng-template #technical>
  <div class="step-layout">
    <app-device-type-banner [deviceType]="deviceType"></app-device-type-banner>
    <form class="input-area" [formGroup]="form">
      <div class="key-btn-box">
        <button
          mat-flat-button
          color="primary"
          class="key-btn"
          (click)="fileInput.click()"
        >
          Upload Public Key File
        </button>
        <app-loader *ngIf="loading"></app-loader>
        <p *ngIf="fileUploadForm.hasError('certFormat')" class="warning">
          Please upload a valid X.509 certificate!
        </p>
        <div *ngIf="certificate$ | async as cert" class="file-preview">
          <mat-icon class="file-icon">task</mat-icon>
          <p>{{ cert.name }}</p>
        </div>
      </div>
      <ng-container formGroupName="technical">
        <input
          #fileInput
          hidden="true"
          type="file"
          onclick="this.value=null"
          (change)="fileInputChange($event)"
          accept=".pem, .cer"
        />
      </ng-container>
    </form>
  </div>
</ng-template>

<ng-template #position>
  <div class="step-layout">
    <app-device-type-banner [deviceType]="deviceType"></app-device-type-banner>
    <app-select-position-map
      class="input-area"
      (setPosition)="onSetPosition($event)"
    ></app-select-position-map>
  </div>
</ng-template>

<ng-template #metadata>
  <div class="step-layout">
    <app-device-type-banner [deviceType]="deviceType"></app-device-type-banner>
    <form [formGroup]="form" class="input-area">
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          #description
          matInput
          formControlName="description"
        ></textarea>
      </mat-form-field>
      <app-metadata-form
        (metadataChange)="onMetadataChange($event)"
      ></app-metadata-form>
    </form>
  </div>
</ng-template>
