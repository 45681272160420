import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DeviceTypeDto } from 'src/models/device-type.models';

@Component({
  selector: 'app-model-item',
  templateUrl: './model-item.component.html',
  styleUrls: ['./model-item.component.scss'],
})
export class ModelItemComponent {
  @Input() model: DeviceTypeDto;
  @Input() selectable? = false;
  @Input() editable? = false;
  @Output() deleted = new EventEmitter<string>();
  @Output() selected = new EventEmitter<DeviceTypeDto>();

  constructor(
    public auth: AuthService,
    public dataLoader: DataLoaderService,
  ) {}

  emitSelection() {
    if (this.selectable) {
      this.selected.emit(this.model);
    }
  }

  emitDelete(event) {
    event.stopPropagation();
    this.deleted.emit(this.model.device_type_id);
  }

  toggleDisable(event) {
    event.stopPropagation();
    this.dataLoader
      .changeDeviceTypeActiveState(
        this.model.device_type_id,
        !this.model.disabled,
      )
      .subscribe();
  }
}
