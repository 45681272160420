import { WizardStep } from 'src/models/app-routing.models';

const GENERAL_INFORMATION_TEXT = 'General information';
const POSITION_TEXT = 'Position';
const TECHNICAL_INFORMATION_TEXT = 'Technical information';
const METADATA_TEXT = 'Metadata';

export const fireflyCreationSteps: Pick<WizardStep, 'title'>[] = [
  { title: GENERAL_INFORMATION_TEXT },
  { title: TECHNICAL_INFORMATION_TEXT },
  { title: POSITION_TEXT },
  { title: METADATA_TEXT },
];

export const defaultCreationSteps: Pick<WizardStep, 'title'>[] = [
  { title: GENERAL_INFORMATION_TEXT },
  { title: POSITION_TEXT },
  { title: METADATA_TEXT },
];

export const iotcoreCreationSteps: Pick<WizardStep, 'title'>[] = [
  { title: GENERAL_INFORMATION_TEXT },
  { title: TECHNICAL_INFORMATION_TEXT },
  { title: POSITION_TEXT },
  { title: METADATA_TEXT },
];
