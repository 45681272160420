<ng-container *ngIf="routesImpacted$ | async as routesImpacted; else loader">
  <div mat-dialog-content>
    <p>
      You are about to move
      <strong
        >{{ data.deviceIds.length }}
        {{ data.deviceIds.length > 1 ? 'devices' : 'device' }}</strong
      >
      to the site <strong>{{ data.destinationNode.name }}</strong
      >.
    </p>
    <ng-container *ngIf="routesImpacted.length > 0">
      <p>
        This will impact the routing rules listed below and might interrupt the
        IoT data flow from
        {{ data.deviceIds.length === 1 ? 'this device' : 'these devices' }} to
        your business application.
      </p>
      <ul>
        <li *ngFor="let routeImpacted of routesImpacted">
          <a
            [routerLink]="[
              '/home/routing/details/',
              encodeURIComponent(routeImpacted.id)
            ]"
            target="_blank"
            >{{ routeImpacted.name }}</a
          ><mat-icon>open_in_new</mat-icon>
        </li>
      </ul>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
      Confirm
    </button>
  </div>
</ng-container>
<ng-template #loader><app-loader></app-loader></ng-template>
