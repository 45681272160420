<form novalidate [formGroup]="form" class="filter">
  <mat-form-field appearance="outline">
    <mat-label>Source</mat-label>

    <mat-select formControlName="source" [compareWith]="compareSourceFn">
      <mat-option *ngFor="let item of groupedTypes$ | async" [value]="item">
        <div class="option-item">
          <span>{{ item?.source?.metadata?.label }}</span>
          <img
            [src]="item?.source?.metadata?.logo_url"
            alt="Data source logo"
          />
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Manufacturer</mat-label>

    <mat-select formControlName="brand" [compareWith]="compareBrandFn">
      <mat-option
        *ngFor="let item of form.get('source')!.value?.brands"
        [value]="item"
      >
        <div class="option-item">
          <span>{{ item?.brand?.brand_id }}</span>
          <img [src]="item?.brand?.metadata?.logo_url" alt="Brand logo" />
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Model</mat-label>

    <mat-select formControlName="model" [compareWith]="compareModelFn">
      <mat-option
        *ngFor="let item of form.get('brand')?.value?.models"
        [value]="item"
      >
        <div class="option-item">
          <span>{{ item?.metadata?.label }}</span>
          <img [src]="item?.metadata?.device_logo_url" alt="Device photo" />
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
