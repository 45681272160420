import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { MathNode, parse, SymbolNode } from 'mathjs';
import { catchError, first, map, Observable, of } from 'rxjs';

export function variablesValidator(
  variablesNames$: Observable<string[]>,
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return variablesNames$.pipe(
      first(),
      map((variables) => {
        const formValue = control.value;
        if (!formValue) {
          return null;
        }
        const unknownVariables: string[] = [];

        parse(formValue).traverse((input: MathNode) => {
          if (input.type === 'SymbolNode') {
            const variable = (input as SymbolNode).name;
            if (!variables.includes(variable)) unknownVariables.push(variable);
          }
        });

        if (unknownVariables.length === 1)
          return { unknownVariables: unknownVariables };
        return unknownVariables.length > 0
          ? { unknownVariables: unknownVariables.join(', ') }
          : null;
      }),
      catchError(() => of(null)),
    );
  };
}

export function variableAlreadyExistValidator(
  variablesNames$: Observable<string[]>,
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return variablesNames$.pipe(
      first(),
      map((variables) => {
        const formValue = control.value;
        if (!formValue) {
          return null;
        }
        const alreadyExist = variables.includes(formValue);
        return alreadyExist ? { alreadyExist: formValue } : null;
      }),
      catchError(() => of(null)),
    );
  };
}
