<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title> Help </mat-panel-title>
  </mat-expansion-panel-header>

  <p>The data selection is done with the aid of a MQTT topic pattern.</p>
  <p class="highlighted">
    There are two different ways to create your topic pattern:
  </p>
  <p>
    You can either select an element in the project hierarchy below by simply
    clicking the
    <span><mat-icon class="small-icon">panorama_fish_eye</mat-icon></span>
    next to it. The pattern matching your selection will be automatically
    created for you. This method is simple and save, however it offers limited
    possiblities.
  </p>
  <p>
    The second method is to manually enter the desired pattern in the input
    field at the bottom of the page. Latter allows the use of topic level
    wildcards and therefore a more precise data selection.
  </p>
  <p>Need an explanation of MQTT topic patterns?</p>
  <button mat-button color="primary" (click)="openMqttHelp()">
    Click here
  </button>
</mat-expansion-panel>
