import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/app/services/shared.module';

import { LoginComponent } from './login.component';

const COMPONENTS = [LoginComponent];

@NgModule({
  imports: [
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class LoginModule {}
