import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Package from 'src/../package.json';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { environment } from 'src/environments/environment';
import { DeviceManagementService } from './device-management/device-management.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  supportUrl = environment.supportGoogleSheetUrl;
  title$ = this.interfaceState.mainTitle$;
  version = Package.version;

  constructor(
    public auth: AuthService,
    private router: Router,
    public deviceManagement: DeviceManagementService,
    public interfaceState: InterfaceStateService,
  ) {}

  public onLogin() {
    this.auth.login();
  }

  public onLogout() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

  public isActive(url: string): boolean {
    return this.router.url.includes(url);
  }
}
