import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { BrandCreationComponent } from 'src/app/pages/main/catalogue/brand-creation/brand-creation.component';
import { DevicesService } from 'src/app/services/http/devices.service';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import {
  Brand,
  DeviceTypeDto,
  DeviceTypeInfo,
} from 'src/models/device-type.models';
import { DataLoaderService } from '../../../services/state/data/data-loader.service';
import { ModelCreationComponent } from './model-creation/model-creation.component';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss'],
})
export class CatalogueComponent {
  screensizeIndex$ = this.interfaceState.breakpointIndex$;
  filteredModels$ = this.data.deviceTypes$;
  models$ = this.data.deviceTypes$;
  dataTags$ = this.data.dataTags$;
  sources$ = this.data.sources$;
  brands$ = this.data.brands$;
  tileView: boolean;
  selectedBrand: Brand;
  creationParams = { step: 1 };

  private readonly HOME_CATALOGUE_TEXT = 'home/catalogue';

  constructor(
    private dialog: MatDialog,
    private dataLoader: DataLoaderService,
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
    private deviceHttp: DevicesService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this._loadData();
  }

  modelLoader(models) {
    return models !== undefined;
  }

  private _loadData() {
    combineLatest([
      this.data.deviceTypes$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
      ),
      this.data.brands$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  onFilterChanged(models: DeviceTypeDto[]): void {
    this.filteredModels$ = of(models);
  }

  deleteModel(modelId: string): void {
    const confirmationMessage = 'This action will delete the device model!';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmationMessage,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((answer) => !!answer),
        switchMap(() => this.deviceHttp.deleteDeviceType(modelId)),
        tap(() => this.data.deleteDeviceType(modelId)),
        tap(() => this.snackBar.open('Brand updated', 'close')),
      )
      .subscribe();
  }

  viewDetails(modelId: string): void {
    this.router.navigate(['details', modelId], {
      relativeTo: this.route,
    });
  }

  editBrand(brand: Brand) {
    this.dialog.open(BrandCreationComponent, { data: brand });
  }

  createBrand() {
    this.dialog.open(BrandCreationComponent);
  }

  createDeviceType() {
    this.dialog
      .open(ModelCreationComponent, {})
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        first(),
        switchMap((res) =>
          this.dataLoader.createDeviceType(
            res.info as DeviceTypeInfo,
            res.image,
          ),
        ),
        tap((dT) => {
          this.router.navigate(['details', dT.device_type_id], {
            relativeTo: this.route,
          });
        }),
      )
      .subscribe();
  }

  public setViewSelection(tileView: boolean): void {
    this.tileView = tileView;
  }
}
