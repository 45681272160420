<section class="filter mat-elevation-z4">
  <div *ngIf="(screensizeIndex$ | async) >= 3" class="filter-banner">
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </div>
  <mat-expansion-panel
    *ngIf="(screensizeIndex$ | async) < 3"
    class="mat-elevation-z0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Filter </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </mat-expansion-panel>
</section>
<ng-container
  *appLoader="let routings; on: filteredRoutings$; with: routingLoader"
>
  <app-no-result *ngIf="!routings.length"></app-no-result>
  <div class="tile-view" *ngIf="tileView">
    <div
      class="green-border"
      [ngClass]="{ 'gray-border': !routing.is_active }"
      *ngFor="let routing of routings"
    >
      <app-routing-list-element [routing]="routing"></app-routing-list-element>
    </div>
  </div>
  <div class="list-view" *ngIf="!tileView">
    <div class="list-header mat-elevation-z4">
      <div class="list-row">
        <div class="list-col">Name</div>
        <div class="list-col">Destination</div>
        <div class="list-col">Devices</div>
        <div class="list-col">Data</div>
        <div class="list-col">Status</div>
        <div class="list-col">Actions</div>
      </div>
    </div>
    <div class="list-body">
      <div
        *ngFor="let routing of filteredRoutings$ | async"
        class="list-row mat-elevation-z4"
      >
        <div class="list-col">{{ routing.name }}</div>
        <div class="list-col">
          {{ routing.destination_name }} ({{ routing.destination_type }})
        </div>
        <div class="list-col">{{ routing.devices_number }}</div>
        <div class="list-col">{{ routing.data_number }}</div>
        <div class="list-col">
          <mat-icon
            svgIcon="custom-routing-{{
              routing.is_active ? 'active' : 'paused'
            }}"
            class="blue-icon"
            [matTooltip]="routing.is_active ? 'Active' : 'Paused'"
          ></mat-icon>
        </div>
        <div class="list-col">
          <button
            mat-button
            class="open-btn"
            (click)="openDetails(routing.id)"
            color="primary"
          >
            open
          </button>
        </div>
      </div>
    </div>
  </div>

  <button
    mat-fab
    color="primary"
    class="floating-button"
    [routerLink]="['new']"
    [queryParams]="creationParams"
    *ngIf="auth.isMaintainer()"
  >
    <mat-icon>add</mat-icon>
  </button>
</ng-container>

<ng-template #formTpl>
  <app-routing-filter
    [routings]="routings$ | async"
    [sites]="sites$ | async"
    (routingsFiltered)="updateFilteredRoutings($event)"
    (tileViewActive)="setViewSelection($event)"
  ></app-routing-filter>
</ng-template>
