import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
import { CustomErrorStateMatcher } from 'src/app/services/util/formErrorStateMatcher';
import {
  MadatoryMessageField,
  MessageField,
} from 'src/models/device-type.models';

@Component({
  selector: 'app-message-field-mapping',
  templateUrl: './message-field-mapping.component.html',
  styleUrls: ['./message-field-mapping.component.scss'],
})
export class MessageFieldMappingComponent implements OnInit {
  @Input() field: MessageField;
  @Input() selectValues: MadatoryMessageField[];
  @Output() valueChanged = new EventEmitter<MessageField>();
  matcher = new CustomErrorStateMatcher();
  input: UntypedFormControl;
  select = true;

  constructor() {}

  get selectOptions(): MadatoryMessageField[] {
    return this.selectValues.filter(
      (field) => !field.valid && field.allowed_types.includes(this.field.type),
    );
  }

  ngOnInit(): void {
    this.input = new UntypedFormControl(
      { value: this.field.output_name, disabled: this.field.deleted },
      [Validators.required, Validators.pattern(/^[a-zA-Z\d-_/]+$/)],
    );

    this.input.valueChanges
      .pipe(
        filter(() => this.select && this.input.enabled),
        tap(() => this.emitField()),
      )
      .subscribe();
  }

  verifyMapping(): boolean {
    return this.input.valid && !!this.field.deleted;
  }

  emitField() {
    this.valueChanged.emit({
      ...this.field,
      output_name: this.input.value,
      deleted: this.verifyMapping(),
    });
  }

  toggleDeleted() {
    this.field.deleted = !this.field.deleted;
    this.emitField();
    this.input.valid && !this.input.disabled
      ? this.input.disable()
      : this.input.enable();
  }

  setSelect(newState: boolean) {
    this.select = newState;
    if (newState) {
      this.emitField();
    }
  }
}
