import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';

@Injectable()
export class LoginResolver implements Resolve<Observable<boolean>> {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  resolve(): Observable<boolean> {
    return this.auth.isAuthorized$().pipe(
      tap((isLogged) => {
        if (isLogged) {
          this.router.navigate(['home']);
        }
      }),
    );
  }
}
