<section class="filter-container mat-elevation-z4">
  <div *ngIf="(screensizeIndex$ | async) >= 2">
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </div>
  <mat-expansion-panel *ngIf="(screensizeIndex$ | async) < 2">
    <mat-expansion-panel-header>
      <mat-panel-title> Filter </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </mat-expansion-panel>
</section>
<app-loader class="loading-message" *ngIf="loading"></app-loader>
<div *ngIf="!loading && emptyList">
  <p class="loading-message">No gateways found</p>
</div>
<section class="list" *ngIf="!loading && !emptyList">
  <div class="list-header mat-elevation-z4">
    <div class="list-row">
      <div class="list-col">Name</div>
      <div class="list-col">Status</div>
      <div class="list-col">ID</div>
      <div class="list-col">LoRaWan radio status</div>
      <div class="list-col">Last uplink</div>
      <div class="list-col">Network connection status</div>
      <div class="list-col">Actions</div>
    </div>
  </div>
  <div class="list-body">
    <div
      *ngFor="let gateway of filteredGateways$ | async"
      class="list-row mat-elevation-z4"
    >
      <div class="list-col">
        {{ gateway.metadata.name }}
      </div>
      <div class="list-col">
        <div *ngIf="!gatewayIsActive(gateway.last_checkin)" class="status red">
          <mat-icon>fiber_manual_record</mat-icon>
          Inactive
        </div>
        <div *ngIf="gatewayIsActive(gateway.last_checkin)" class="status green">
          <mat-icon>fiber_manual_record</mat-icon>Active
        </div>
      </div>
      <div class="list-col">
        {{ gateway.gateway_id }}
      </div>
      <div class="list-col">
        <div class="status-btn" [ngClass]="{ 'bg-green': gprsActive(gateway) }">
          <mat-icon>online_prediction</mat-icon
          >{{ gprsActive(gateway) ? 'Started' : 'No Information' }}
        </div>
      </div>
      <div class="list-col">
        {{
          gateway.statistics.lora_network.updated_at
            | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ'
        }}
      </div>
      <div class="list-col">
        <div
          class="status-btn"
          [ngClass]="{ 'bg-green': networkActive(gateway) }"
        >
          <mat-icon>signal_cellular_alt</mat-icon>
        </div>
      </div>
      <div class="list-col">
        <mat-icon
          class="clickable status-btn bg-blue"
          (click)="openMap(mapTmpl, gateway)"
          matTooltipPosition="below"
          matTooltip="show gateway position"
          >travel_explore</mat-icon
        >
        <mat-icon
          class="clickable status-btn bg-blue"
          (click)="onShowDataVisual(gateway)"
          matTooltipPosition="below"
          matTooltip="load RSSI signal historic"
          >query_stats</mat-icon
        >
      </div>
    </div>
  </div>
</section>

<button
  mat-fab
  color="primary"
  class="floating-button"
  [routerLink]="['import']"
  *ngIf="auth.isMaintainer()"
  matTooltipPosition="below"
  matTooltip="import a gateway from multitech"
>
  <mat-icon>add</mat-icon>
</button>

<ng-template #formTpl>
  <app-gateway-filter
    [sites]="sites$ | async"
    [gateways]="gateways$ | async"
    (gatewaysFiltered)="updateFilteredGateways($event)"
  ></app-gateway-filter>
</ng-template>

<ng-template #mapTmpl>
  <ng-container *ngIf="selectedGateway">
    <div class="map-container">
      <app-gateway-map [gateways]="selectedGateway"></app-gateway-map>
    </div>
    <button mat-button [mat-dialog-close]="false" class="dialog-btn">
      Close
    </button>
  </ng-container>
</ng-template>
