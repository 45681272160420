import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { delay, map, tap } from 'rxjs/operators';
import { DeviceHierarchyStoreService } from 'src/app/services/state/data/device-hierarchy/device-hierarchy-store.service';
import { HierarchyNode } from 'src/models/device-hierarchy.models';

@Component({
  selector: 'app-pattern-builder',
  templateUrl: './pattern-builder.component.html',
  styleUrls: ['./pattern-builder.component.scss'],
})
export class PatternBuilderComponent implements OnInit {
  @Output() patternChanged = new EventEmitter<string>();
  selectedNode = new UntypedFormControl(null);
  treeControl = new NestedTreeControl<HierarchyNode>((node) => node.children);
  dataSource: MatTreeNestedDataSource<HierarchyNode> | null;

  constructor(private deviceHierarchy: DeviceHierarchyStoreService) {}

  ngOnInit(): void {
    this.deviceHierarchy.deviceHierarchyTree$
      .pipe(
        tap((hierarchy) => this.updateDatasource(hierarchy)),
        delay(2),
      )
      .subscribe();

    this.selectedNode.valueChanges
      .pipe(
        tap((node) => this.treeControl.expand(node)),
        map((node: HierarchyNode) => {
          const path = this.deviceHierarchy.getPathForNode(node);
          return node.isData ? path : `${path}/#`;
        }),
        tap((pattern) => this.patternChanged.emit(pattern)),
      )
      .subscribe();
  }

  public hasChildren(_: number, node: HierarchyNode): boolean {
    return !!node.children.length;
  }

  public nodeIsSelected(node: HierarchyNode): boolean {
    return this.selectedNode.value
      ? this.selectedNode.value.id === node.id
      : false;
  }

  private updateDatasource(data: HierarchyNode[]) {
    this.dataSource = null;
    setTimeout(() => {
      this.dataSource = new MatTreeNestedDataSource<HierarchyNode>();
      this.dataSource.data = data;
    });
  }
}
