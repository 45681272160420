<ng-container *ngIf="!creationRequestLoader">
  <h1 mat-dialog-title>Multi-device provisioning</h1>

  <mat-dialog-content>
    <div class="content">
      <p>Upload a .csv file to provide multiple firefly devices at once.</p>

      <p>
        Use the button "Download template" at the bottom of this dialog to
        download the template required to upload them. <br />
      </p>
      <p>
        <small>
          <em>
            Note : region will be "{{ regions[0]?.option }}", data rate will be
            "{{ dataRates[0]?.option }}", devices will be added to "{{ path }}"
          </em>
        </small>
      </p>

      <app-device-providing-model-select
        [formControl]="deviceTypeControl"
        sourceFilter="firefly"
      ></app-device-providing-model-select>

      <p><!-- Empty line --></p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="actions">
      <button
        mat-raised-button
        color="primary"
        (click)="uploadFile()"
        [disabled]="!deviceTypeControl.valid"
      >
        Upload CSV file
      </button>
      <button mat-button color="primary" (click)="downloadTemplate()">
        Download template
      </button>
      <span class="flexer"></span>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!httpPayload"
        (click)="uploadDevices()"
      >
        Upload {{ deviceCount || '' }} device{{
          (deviceCount > 2 && 's') || ''
        }}
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="creationRequestLoader && !errorResult && !createdDevices">
  <h1 mat-dialog-title>Multi-device provisioning</h1>

  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="creationRequestLoader && (errorResult || createdDevices)">
  <h1 mat-dialog-title>Multi-device provisioning</h1>

  <mat-dialog-content>
    <div class="content">
      <p *ngIf="createdDevices.length">
        {{ createdDevices.length }} device{{
          (createdDevices.length >= 2 && 's have') || ' has'
        }}
        been successfully created!
      </p>
      <p *ngIf="errorResult">
        {{ failureCount }} device{{ (failureCount >= 2 && 's') || '' }} could
        not be created. Download the feedback CSV for more information.
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="actions">
      <button
        *ngIf="errorResult"
        mat-raised-button
        color="primary"
        (click)="downloadErrorResults(errorResult)"
      >
        Download Feedback CSV
      </button>

      <span class="flexer"></span>
      <button mat-raised-button color="primary" (click)="ref.close()">
        Close
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>
