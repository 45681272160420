import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input() img: File;
  @Input() imgPreviewUrl?: string;
  @Output() imgSelected = new EventEmitter<File>();
  id = Date.now();

  ngOnInit(): void {
    if (this.img) {
      this.previewImg(this.img);
    }
  }

  selectionChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length > 0 && files[0].type.match(/image\/*/)) {
      this.previewImg(files[0]);
      this.imgSelected.emit(files[0]);
    } else {
      this.imgPreviewUrl = undefined;
    }
  }

  previewImg(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => (this.imgPreviewUrl = reader.result as string);
  }
}
