import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { catchError, of, tap } from 'rxjs';
import { routingDestinationTypes } from 'src/app/pages/main/routing-list/routing-creation/routing-creation.config';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { Interface } from 'src/models/data-routing.models';

@Component({
  selector: 'app-interface-creation',
  templateUrl: './interface-creation.component.html',
  styleUrls: ['./interface-creation.component.scss'],
})
export class InterfaceCreationComponent implements OnInit {
  form = this.buildForm;
  saving = false;
  routingDestinationTypes = routingDestinationTypes;
  sites$ = this.data.sites$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public interf: Interface | undefined,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private snackBar: MatSnackBar,
    private dialogRef: MatLegacyDialogRef<InterfaceCreationComponent>,
  ) {}

  ngOnInit(): void {
    if (this.interf) {
      this.prefillForm(this.interf);
    } else {
      this.addSite('');
    }
  }

  private get buildForm(): FormGroup {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      sites: new FormArray([]),
      destination: new FormControl(
        {
          value: 'mes',
          disabled: !!this.interf,
        },
        Validators.required,
      ),
      topic: new FormControl(
        { value: '', disabled: !!this.interf },
        Validators.required,
      ),
    });
  }

  private prefillForm(interf: Interface): void {
    this.form.setValue({
      name: interf.metadata.name ?? '',
      destination: interf.metadata.destination.toLowerCase(),
      sites: [],
      topic: interf.topic,
    });

    for (const site of interf.tags) {
      this.addSite(site);
    }
  }

  get sites(): FormArray {
    return this.form.controls['sites'] as FormArray;
  }

  public siteAlreadySelected(tag_id: string, index: number): boolean {
    const sites: string[] = this.form.value.sites;
    const ix = sites.indexOf(tag_id);
    return ix >= 0 && ix !== index;
  }

  public addSite(site: string | null = ''): void {
    this.sites.push(
      new FormControl({ value: site, disabled: false }, Validators.required),
    );
  }

  public removeSite(index: number): void {
    this.sites.removeAt(index);
  }

  public get interfaceInfo(): Interface {
    return {
      interface_id: this.interf?.interface_id || '',
      topic: this.form.value.topic ?? this.interf?.topic,
      metadata: {
        name: this.form.value.name,
        destination:
          this.form.value.destination ?? this.interf?.metadata.destination,
      },
      tags: this.form.value.sites ?? this.interf?.tags,
      data_format: 0,
    };
  }

  public async submit() {
    this.saving = true;
    if (!this.interf?.interface_id) {
      this.dataLoader
        .createInterface(this.interfaceInfo)
        .pipe(
          tap(() => {
            this.snackBar.open('Interface was successfully created!', 'Close');
            this.dialogRef.close();
          }),
          catchError((error) => {
            this.saving = false;
            return of(error);
          }),
        )
        .subscribe();
    } else {
      this.dataLoader
        .updateInterface(this.interfaceInfo)
        .pipe(
          tap(() => {
            this.snackBar.open('Interface was successfully updated!', 'Close');
            this.dialogRef.close();
          }),
          catchError((error) => {
            this.saving = false;
            return of(error);
          }),
        )
        .subscribe();
    }
  }
}
