<div class="banner mat-typography mat-elevation-z3">
  <h2>
    {{ isEditMode ? ' Update a routing rule' : 'Create a new routing rule' }}
  </h2>
  <button mat-icon-button [routerLink]="['/home/routing']">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div class="steps mat-typography" *appLoader="loader$; with: loadCondition">
  <app-form-stepper
    [activeStep]="activeStepConfig | async"
    [stepValid]="stepValidator()"
    (submitted)="submitForm()"
  >
    <div *ngIf="activeStepIndex === 1">
      <ng-template *ngTemplateOutlet="stepOne"></ng-template>
    </div>
    <div *ngIf="activeStepIndex === 2">
      <ng-template *ngTemplateOutlet="stepTwo"></ng-template>
    </div>
    <div *ngIf="activeStepIndex === 3">
      <ng-template *ngTemplateOutlet="stepThree"></ng-template>
    </div>
  </app-form-stepper>
</div>

<ng-template #stepOne>
  <div class="stepOne" [formGroup]="stepOneForm">
    <div class="mat-elevation-z4 shadow-box">
      <p class="input-heading">Name your routing rule to personalize it</p>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div class="mat-elevation-z4 shadow-box">
      <p class="input-heading">Set the state of your route after creation</p>
      <mat-checkbox color="primary" formControlName="is_active">
        <span class="checkbox-label"
          >Active
          <mat-icon svgIcon="custom-routing-active" class="blue-icon"></mat-icon
        ></span>
      </mat-checkbox>
    </div>
  </div>
</ng-template>

<ng-template #stepTwo>
  <div class="stepTwo" [formGroup]="stepTwoForm">
    <div class="help-container">
      <app-pattern-builder-help></app-pattern-builder-help>
    </div>
    <div class="mat-elevation-z4 shadow-box">
      <app-pattern-builder
        (patternChanged)="stepTwoForm.get('pattern')!.setValue($event)"
      ></app-pattern-builder>
    </div>
    <div class="mat-elevation-z4 pattern-container">
      <mat-form-field appearance="outline">
        <mat-label>Topic pattern for data selection</mat-label>
        <input matInput formControlName="pattern" />
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="openMqttHelp()">
        <mat-icon>help</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #stepThree>
  <div class="stepThree">
    <app-destination-form
      [destinationForm]="stepThreeForm"
      [data]="exportedData$ | async"
      [pattern]="stepTwoForm.value.pattern"
      [interfaces]="interfaces$ | async"
      [formValues]="routeToEdit"
      [projectId]="stepTwoForm.value.projectId"
    ></app-destination-form>
  </div>
</ng-template>
