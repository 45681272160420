<div mat-dialog-content>
  <ng-container *ngIf="isHtml; then htmlTpl; else rawTpl"></ng-container>

  <ng-template #rawTpl>
    <p>{{ messageToConfirm }}</p>
  </ng-template>

  <ng-template #htmlTpl>
    <p [innerHTML]="messageToConfirm.html"></p>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
</div>
