<div class="wrapper">
  <div class="input-container">
    <div class="title-box">
      <h3 class="title">Source origin</h3>
      <h4 class="subtitle">Paste Payload JSON to parse attributes</h4>
    </div>
    <div class="title-box">
      <h3 class="title">Source destination / Mapping summary</h3>
      <h4 class="subtitle">
        This build can be mapped to a origin field. Select it using the select
        button "<mat-icon>arrow_drop_down</mat-icon>"
      </h4>
    </div>
    <div class="input">
      <app-json-input
        [messageJson]="parserCreation.jsonBlueprint()?.config.json_sample"
        (fieldsParsed)="fieldsParsed($event)"
      ></app-json-input>
    </div>
    <div class="validation">
      <app-field-hint
        *ngFor="let field of mappingValidator"
        [field]="field"
      ></app-field-hint>
    </div>
  </div>

  <div class="mapping">
    <app-message-field-mapping
      *ngFor="let field of parserForm.get('data_fields')?.value; let i = index"
      [selectValues]="mappingValidator"
      [field]="field"
      (valueChanged)="fieldChanged($event, i)"
    ></app-message-field-mapping>
  </div>
</div>
