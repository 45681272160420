import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/state/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AlertingService {
  private readonly _selectedSite$ = new BehaviorSubject<string>(this.siteValue);
  private readonly _userOpen$ = new BehaviorSubject<boolean>(true);
  private readonly _devicesOpen$ = new BehaviorSubject<boolean>(false);
  private readonly _deviceFilter$ = new BehaviorSubject<UntypedFormGroup>(
    this.buildDeviceFilter(),
  );

  constructor(private auth: AuthService) {}

  private get siteValue(): string {
    return this.auth.userHasMultipleSiteAccess
      ? ''
      : this.auth.accessibleSites[0];
  }

  public setSelectedSite(site: string): void {
    this._selectedSite$.next(site);
  }

  public get selectedSite$(): Observable<string> {
    return this._selectedSite$.asObservable();
  }

  public get selectedSite(): string {
    return this._selectedSite$.value;
  }

  public setUserOpen(state: boolean): void {
    this._userOpen$.next(state);
  }

  public get userOpen(): boolean {
    return this._userOpen$.value;
  }

  public setDevicesOpen(state: boolean): void {
    this._devicesOpen$.next(state);
  }

  public get devicesOpen(): boolean {
    return this._devicesOpen$.value;
  }

  public setdeviceFilter(filterForm: UntypedFormGroup) {
    this._deviceFilter$.next(filterForm);
  }

  public get deviceFilter$(): Observable<UntypedFormGroup> {
    return this._deviceFilter$.asObservable();
  }

  public get deviceFilter(): UntypedFormGroup {
    return this._deviceFilter$.value;
  }

  public resetdeviceFilter(): void {
    this._deviceFilter$.next(this.buildDeviceFilter());
  }

  public buildDeviceFilter(
    brandId = '',
    source = '',
    deviceType = '',
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      brand_id: new UntypedFormControl(brandId),
      source: new UntypedFormControl(source),
      device_type_id: new UntypedFormControl(deviceType),
    });
  }
}
