<form [formGroup]="form" *ngIf="form" class="filter">
  <mat-form-field class="filter-field">
    <mat-label>Site</mat-label>
    <mat-select formControlName="site">
      <mat-option [value]="0">All</mat-option>
      <mat-option *ngFor="let site of sites" [value]="site.tag_id">
        {{ site?.metadata?.iso_code }} -
        {{ site?.metadata?.site_long_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
