import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-counter-chip',
  templateUrl: './counter-chip.component.html',
  styleUrls: ['./counter-chip.component.scss'],
})
export class CounterChipComponent {
  @Input() label: string;
  @Input() count: number;
  constructor() {}
}
