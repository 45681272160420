import { Component, Inject, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { Brand } from 'src/models/device-type.models';

@UntilDestroy()
@Component({
  selector: 'app-brand-creation',
  templateUrl: './brand-creation.component.html',
  styleUrls: ['./brand-creation.component.scss'],
})
export class BrandCreationComponent {
  // interface state
  imagePreviewUrl: string = (this.brand?.metadata.logo_url as string) || '';

  // user input
  brandForm = this.buildBrandForm;
  imageFile = new FormControl(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public brand: Brand | undefined,
    private dataLoader: DataLoaderService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<BrandCreationComponent>,
  ) {}

  private get buildBrandForm() {
    const bd = this.brand;

    return new FormBuilder().nonNullable.group({
      name: [(bd?.brand_id as string) || '', [Validators.required]],
      description: [bd?.metadata.description || ''],
    });
  }

  public delete(templateRef: TemplateRef<unknown>) {
    this.dialog
      .open(templateRef)
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.dataLoader.deleteBrand(this.brand!.brand_id).subscribe(() => {
            this.dialogRef.close();
          });
        }
      });
  }

  public confirm() {
    if (this.brand) {
      this.dataLoader
        .updateBrand(this.formValues)
        .subscribe(() => this.dialogRef.close());
    } else {
      this.dataLoader
        .createBrand(this.formValues)
        .subscribe(() => this.dialogRef.close());
    }
  }

  get formValues() {
    return {
      ...this.brandForm.getRawValue(),
      // TODO: image_file may be null, catch this case before sending a request
      image_file: this.imageFile.value as unknown as File,
    };
  }
}
