import { WizardStep } from 'src/models/app-routing.models';
import { DestinationType } from 'src/models/data-routing.models';

export interface SupportedDestionation {
  label: string;
  id: DestinationType;
}

export const routingCreationSteps: Pick<WizardStep, 'title'>[] = [
  { title: 'General Information' },
  { title: 'Data Selection' },
  { title: 'Routing Destination' },
];

export const routingDestinationTypes: SupportedDestionation[] = [
  { label: 'Google Sheet', id: 'sheet' },
  { label: 'MES', id: 'mes' },
  { label: 'Bigquery', id: 'bigquery' },
];
