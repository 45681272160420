<div class="list-header">
  <h4 class="list-title">Details Data: {{ displayedData.length }}</h4>
  <button
    mat-button
    class="export-btn"
    (click)="exportAsCSV()"
    *ngIf="displayedData.length"
    matTooltipPosition="below"
    matTooltip="download csv with routing information"
  >
    Export
  </button>
</div>

<div class="routes">
  <ng-container *ngFor="let route of groupedRoutes">
    <ng-container
      [ngTemplateOutlet]="routeTpl"
      [ngTemplateOutletContext]="{ route: route, offset: 0, path: '' }"
    ></ng-container>
  </ng-container>
</div>

<ng-template
  #routeTpl
  let-route="route"
  let-offset="offset"
  let-hasSibling="hasSibling"
  let-path="path"
>
  <div class="item" [class.has-sibling]="hasSibling">
    <div class="row" [class.root]="!offset">
      <span class="branch" *ngIf="offset"></span>
      <span class="action" *ngIf="!route.children.length">
        <button
          mat-icon-button
          #mat="matTooltip"
          [matTooltip]="tooltip"
          (click)="onCopyToClipBoard(path + route.value , mat)"
          (pointerout)="resetTooltip()"
        >
          <mat-icon>file_copy</mat-icon>
        </button>
      </span>
      <span class="value" #valueTpl>
        <span>{{ route.value }} </span
        ><span *ngIf="getDeviceName(route.value).length" class="dev-name">
          ({{ getDeviceName(route.value) }})</span
        >
      </span>
    </div>
    <div class="children" *ngIf="route.children">
      <ng-container *ngFor="let subroute of route.children; let i = index">
        <ng-container
          [ngTemplateOutlet]="routeTpl"
          [ngTemplateOutletContext]="{
            route: subroute,
            offset: offset + 1,
            hasSibling: !!route.children[i + 1],
            path: path + route.value + '/'
          }"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>


