import { Component } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { DeviceManagementService } from 'src/app/pages/main/device-management/device-management.service';
import { DataLoaderService } from '../../../../services/state/data/data-loader.service';
import { DataStoreService } from '../../../../services/state/data/data-store.service';

@Component({
  selector: 'app-device-creation',
  templateUrl: './device-creation.component.html',
  styleUrls: ['./device-creation.component.scss'],
})
export class DeviceCreationComponent {
  types$ = this.data.deviceTypes$;

  constructor(
    public deviceManagement: DeviceManagementService,
    private dataLoader: DataLoaderService,
    private data: DataStoreService,
  ) {
    combineLatest([
      this.data.deviceTypes$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
      ),
      this.data.brands$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
      ),
      this.data.devices$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDevices(),
        ),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe();
  }
}
