import { Component, Input } from '@angular/core';
import { MadatoryMessageField } from 'src/models/device-type.models';

@Component({
  selector: 'app-field-hint',
  templateUrl: './field-hint.component.html',
  styleUrls: ['./field-hint.component.scss'],
})
export class FieldHintComponent {
  @Input() field: MadatoryMessageField;
  description = false;

  toggleDescription() {
    this.description = !this.description;
  }
}
