import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DeviceDto } from 'src/models/device.models';

@Component({
  selector: 'app-device-picture-update',
  templateUrl: './device-picture-update.component.html',
  styleUrls: ['./device-picture-update.component.scss'],
})
export class DevicePictureUpdateComponent {
  pictureForm = new UntypedFormGroup({
    image_file: new UntypedFormControl(null, [Validators.required]),
  });

  deviceImage = this.data.imageUrl;
  deviceImageFile: File;

  id = `img-${Date.now()}`;

  constructor(
    public dialogRef: MatDialogRef<DevicePictureUpdateComponent>,
    private dataLoader: DataLoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: { device: DeviceDto; imageUrl: string },
  ) {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dataLoader
      .updateDevicePicture(this.data.device.device_id, {
        image_file: this.deviceImageFile,
      })
      .subscribe((dev) => this.dialogRef.close(dev));
  }

  selectionChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files!;
    if (files.length > 0 && files[0].type.match(/image\/*/)) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.deviceImage = reader.result as string);
      this.deviceImageFile = files[0] as File;
    }
  }
}
