import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import {
  icon,
  latLng,
  latLngBounds,
  map,
  marker,
  markerClusterGroup,
  tileLayer,
} from 'leaflet';
import 'leaflet.markercluster';
import { environment } from 'src/environments/environment';
import { DeviceDto } from 'src/models/device.models';

const mapOptions = {
  layers: [
    tileLayer(environment.openStreetMap.layerTilesUrl, {
      maxZoom: 19,
      attribution: environment.openStreetMap.attribution,
    }),
  ],
};

@Component({
  selector: 'app-display-position-map',
  templateUrl: './display-position-map.component.html',
  styleUrls: ['./display-position-map.component.scss'],
})
export class DisplayPositionMapComponent implements AfterViewInit, OnDestroy {
  @Input() set devices(dev: DeviceDto[]) {
    this.bounds = this.mapDevicesToBounds(dev);
    this.map
      ? this.updateMap(dev)
      : (this.markerLayer = this.mapDevicesToMarkerLayer(dev));
  }
  public mapContainerId = Date.now().toString();
  private map: L.Map;
  private markerLayer: L.LayerGroup = markerClusterGroup();
  private bounds: L.LatLngBounds;

  ngAfterViewInit() {
    if (document.getElementById(this.mapContainerId)) {
      this.map = map(this.mapContainerId, mapOptions);
      this.markerLayer.addTo(this.map);
      this.map.fitBounds(this.bounds);
    }
  }

  private updateMap(devices: DeviceDto[]): void {
    this.map.removeLayer(this.markerLayer);
    this.markerLayer = this.mapDevicesToMarkerLayer(devices).addTo(this.map);
    this.map.fitBounds(this.bounds);
  }

  private mapDevicesToMarkerLayer(devices: DeviceDto[]): L.LayerGroup {
    const cluster = markerClusterGroup({ showCoverageOnHover: false });
    devices
      .filter((device) => device.metadata.lat && device.metadata.lng)
      .map((device) =>
        marker(latLng(device.metadata.lat, device.metadata.lng), {
          icon: icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/img/marker-icon.png',
            shadowUrl: 'assets/img/marker-shadow.png',
          }),
        }).bindTooltip(`${device.metadata.name}`),
      )
      .forEach((mkr) => cluster.addLayer(mkr));
    return cluster;
  }

  private mapDevicesToBounds(devices: DeviceDto[]): L.LatLngBounds {
    const coords = devices
      .filter((device) => device.metadata.lat && device.metadata.lng)
      .map((device) => latLng(device.metadata.lat, device.metadata.lng));
    return coords.length === 0
      ? latLngBounds([
          [
            environment.openStreetMap.defaultMapCenter.lat,
            environment.openStreetMap.defaultMapCenter.lng,
          ],
        ])
      : latLngBounds(coords);
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
}
