import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { DevicesService } from 'src/app/services/http/devices.service';
import { HierarchyNode } from 'src/models/device-hierarchy.models';

@Injectable({
  providedIn: 'root',
})
export class DeviceHierarchyStoreService {
  private readonly _deviceHierarchyTree$ = new BehaviorSubject(
    [] as HierarchyNode[],
  );

  // -------------- SEARCH FEATURE --------------

  public searchControl = new UntypedFormControl('');

  private _searchControlChanges = this.searchControl.valueChanges.pipe(
    startWith(''),
    debounceTime(250),
  );

  // Options to provide to the autocomplete
  private _searchOptions = new BehaviorSubject<string[]>([]);
  // Mapper used to have great performances on the search
  private _searchMapper: HierarchyNodeSearchMapper = {};

  // Filtered nodes, resulting from the mapper and the search
  public filteredHierarchyTree$ = combineLatest([
    this.deviceHierarchyTree$.pipe(
      tap((nodes) =>
        this._createMapperAndExtractNodeNamesForQuickSearch(nodes),
      ),
    ),
    this._searchControlChanges,
  ]).pipe(
    map(([nodes, search]) => this._filterNodesBySearch(search, nodes)),
    shareReplay(1),
  );

  // Filtered autocomplete options according to what's already typed
  public searchOptions$ = combineLatest([
    this._searchOptions.asObservable(),
    this._searchControlChanges,
  ]).pipe(
    map(([options, search]) =>
      options.filter((option) =>
        option.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      ),
    ),
  );

  // ----------------------------

  constructor(private http: DevicesService) {}

  public get deviceHierarchyTree$(): Observable<HierarchyNode[]> {
    return this._deviceHierarchyTree$.asObservable();
  }

  public setDeviceHierarchyTree(hierarchyTree: HierarchyNode[]): void {
    this._deviceHierarchyTree$.next(hierarchyTree);
  }

  public loadDeviceHierarchy(): Observable<HierarchyNode[]> {
    return this.http.getDeviceHierarchyTree().pipe(
      map((tree) => this.recursiveTreeSort(tree)),
      tap((tree) => this.setDeviceHierarchyTree(tree)),
    );
  }

  public recursiveTreeSort(tree: HierarchyNode[]): HierarchyNode[] {
    const recursive = (branch: HierarchyNode) => {
      branch.children = [
        ...this.getSortedFolders(branch.children),
        ...this.getSortedDevices(branch.children),
      ];
      branch.children.map((child) => recursive(child));
      return branch;
    };
    return this.getSortedFolders(tree).map((branch) => recursive(branch));
  }

  public getSortedFolders(nodes: HierarchyNode[]): HierarchyNode[] {
    return nodes
      .filter((node) => !node.isDevice)
      .sort((node1, node2) => node1.name.localeCompare(node2.name));
  }

  public getSortedDevices(nodes: HierarchyNode[]): HierarchyNode[] {
    return nodes
      .filter((node) => node.isDevice)
      .sort((node1, node2) => node1.name.localeCompare(node2.name));
  }

  public updateNode(node: HierarchyNode): void {
    const hierarchy = this._deviceHierarchyTree$.value;

    const recursive = (branch: HierarchyNode) => {
      if (branch.id === node.id) {
        branch = { ...node };
      } else if (branch.children) {
        branch.children.forEach((child) => recursive(child));
      }
    };
    hierarchy.forEach((node) => recursive(node));
    this._deviceHierarchyTree$.next(hierarchy);
  }

  public getPathForNode(
    node: HierarchyNode,
    hierarchy = this._deviceHierarchyTree$.value,
  ): string {
    let path = '';
    const recursive = (branch: HierarchyNode, newPath = '/') => {
      if (branch === node) {
        path = newPath + node.id;
      } else if (branch.children) {
        newPath += branch.id + '/';
        branch.children.forEach((child) => recursive(child, newPath));
      }
    };

    hierarchy.forEach((branch) => {
      recursive(branch);
    });

    return path;
  }

  public getNodesForPath(
    path: string,
    hierarchy = this._deviceHierarchyTree$.value,
  ): HierarchyNode[] {
    const nodeIds = path.split('/').slice(2);
    const site = hierarchy.find((branch) => branch.id === path.split('/')[1]);
    const nodes: HierarchyNode[] = [];

    if (site) {
      nodes.push(site);
      nodeIds.forEach((id) => {
        const node = nodes[nodes.length - 1].children.find(
          (child) => child.id === id,
        );
        if (node) {
          nodes.push(node);
        }
      });
    }
    return nodes;
  }

  public getSelectedNodeForPath(
    path: string,
    hierarchy = this._deviceHierarchyTree$.value,
  ): HierarchyNode {
    const nodes = this.getNodesForPath(path, hierarchy);
    return nodes[nodes.length - 1];
  }

  /**
   * Search parameters creation.
   * Creates both the mapper and the autocomplete options.
   *
   * Autocomplete options are a list of node names.
   *
   * Mapper is an object of the following form :
   * ```
   * {
   *   "nodeName": [
   *     [rootNode, subRootNode, subsubRootNode, ...],
   *     ...
   *   ]
   * }
   * ```
   *
   * Thanks to this, the search is low-performance cost.
   *
   * For the use of the mapper, see the other function below
   *
   * @see {@link _filterNodesBySearch}
   *
   */
  private _createMapperAndExtractNodeNamesForQuickSearch(
    hierarchy: HierarchyNode[],
  ) {
    const mapper: HierarchyNodeSearchMapper = {};
    const options: string[] = [];

    const recursiveParser = (
      currentNode: HierarchyNode,
      parentNodes: HierarchyNode[],
    ) => {
      currentNode.name && options.push(currentNode.name);
      currentNode.isDevice && options.push(currentNode.id.toString());

      parentNodes.push({ ...currentNode, children: [...currentNode.children] });

      if (!mapper[currentNode.name]) mapper[currentNode.name] = [parentNodes];
      else mapper[currentNode.name].push(parentNodes);

      if (currentNode.isDevice && !mapper[currentNode.id]) {
        mapper[currentNode.id] = [parentNodes];
      } else if (currentNode.isDevice) {
        mapper[currentNode.id].push(parentNodes);
      }

      for (const child of currentNode.children)
        recursiveParser({ ...child }, [...parentNodes]);
    };

    for (const node of hierarchy) recursiveParser({ ...node }, []);

    const finalOptions = options
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.localeCompare(b));

    this._searchOptions.next(finalOptions);
    this._searchMapper = mapper;
  }

  /**
   * Uses the mapper to find nodes that correspond to the query provided.
   *
   * Process :
   *   - Find all keys in the mapper that contain the query
   *   - For each path contained in the mapper's values returned
   *     - Build a copy of the root node (first element of the path), with empty children
   *     - For each subnode in the path
   *       - Add this node to its parent node's children
   *
   * @param search Search string
   */
  private _filterNodesBySearch(search: string, nodes: HierarchyNode[]) {
    if (!search) return nodes;

    const results: HierarchyNode[] = [];

    for (const key in this._searchMapper) {
      if (!key.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        continue;
      const paths = this._searchMapper[key];

      const pathNodes = paths.map((path) => {
        const dupPath = JSON.parse(JSON.stringify(path));

        const rootNode: HierarchyNode = {
          ...dupPath[0],
          children: !dupPath[1] ? dupPath[0].children : [],
        };

        dupPath.slice(1).reduce((acc, node, i) => {
          acc.children = dupPath[i + 1] ? [node] : acc.children;
          return node;
        }, rootNode);

        return rootNode;
      });

      for (const node of pathNodes) {
        if (!results.some((res) => res.name === node.name)) results.push(node);
      }
    }

    return results;
  }
}

type HierarchyNodeSearchMapper = { [key: string]: HierarchyNode[][] };
