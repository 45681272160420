import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PathGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const path = route.queryParams.path;
    const pathValid = path.split('/').length >= 2;

    if (!pathValid) {
      this.router.navigate(['/home/device/manager'], {
        queryParams: { path: route.queryParams.path },
      });
    }
    return pathValid;
  }
}
