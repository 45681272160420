<div class="wrapper mat-elevation-z2" (click)="toggleDescription()">
  <div
    class="overview"
    *ngIf="!description"
    [ngClass]="{ red: !field.optional && !field.valid, blue: field.valid }"
  >
    <p>{{ field.label }}</p>
    <p *ngIf="!field.optional">Mandatory</p>
    <p *ngIf="field.optional">Recommended</p>
    <ng-template *ngTemplateOutlet="icon"></ng-template>
  </div>
  <div
    class="details"
    *ngIf="description"
    [ngClass]="{
      'bg-red': !field.optional && !field.valid,
      'bg-blue': field.valid
    }"
  >
    <p>{{ field.description }}</p>
    <ng-template *ngTemplateOutlet="icon"></ng-template>
  </div>
</div>

<ng-template #icon>
  <mat-icon *ngIf="field.valid">done</mat-icon>
  <mat-icon *ngIf="!field.valid">error_outline</mat-icon>
</ng-template>
