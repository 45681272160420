import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { httpInterceptorProviders } from './services/http/interceptors';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { RouterOutletComponent } from 'src/app/components/router-outlet/router-outlet.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './pages/login/login.module';
import { MainModule } from './pages/main/main.module';
import { UnauthorizedModule } from './pages/unauthorized/unauthorized.module';
import { SharedModule } from './services/shared.module';

@NgModule({
  declarations: [AppComponent, RouterOutletComponent],
  imports: [
    BrowserModule,
    SharedModule,
    MainModule,
    LoginModule,
    UnauthorizedModule,
    AppRoutingModule,
    HttpClientModule,
    LeafletModule,
  ],
  providers: [AngularFireAuth, httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
