import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { filter, map, tap } from 'rxjs/operators';

import { JsonInputResult } from 'src/models/device-type.models';
import { supportedValueTypes } from '../../parser-creation-helper';
import { ParserCreationService } from '../../parser-creation.service';

@Component({
  selector: 'app-json-input',
  templateUrl: './json-input.component.html',
  styleUrls: ['./json-input.component.scss'],
})
export class JsonInputComponent implements OnInit {
  @Input() messageJson: string;
  @Output() fieldsParsed = new EventEmitter<JsonInputResult>();
  input = new UntypedFormControl('');
  isValidJson = true;

  constructor(private parserCreation: ParserCreationService) {}

  ngOnInit(): void {
    this.input.setValue(this.messageJson || '');
    this.input.valueChanges
      .pipe(
        filter((input) => this.isValidJSON(input)),
        map((input) => ({
          json_sample: input,
          fields: this.parserCreation
            .mapJsonToMessageFields(JSON.parse(input))
            .filter((field) => supportedValueTypes.includes(field.type)),
        })),
        tap((result) => this.fieldsParsed.emit(result)),
      )
      .subscribe();
  }

  isValidJSON(input: string): boolean {
    let parsedJson;
    try {
      parsedJson = JSON.parse(input);
    } catch {
      this.isValidJson = false;
      this.fieldsParsed.emit({ json_sample: '', fields: [] });
      return false;
    }
    const recursive = (obj: null | object, path = '') => {
      if (obj) {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          const newPath = path.length ? `${path}/${key}` : key;
          if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            recursive(obj[key], newPath);
          }
        });
      }
    };
    this.isValidJson = true;
    recursive(parsedJson);
    return this.isValidJson;
  }

  clearInput() {
    this.input.setValue('');
  }
}
