<div class="filter-banner mat-elevation-z3">
  <app-model-filter
    [manufacturers]="brands$ | async"
    [models]="models$ | async"
    [sources]="sources$ | async"
    [tags]="dataTags$ | async"
    [showViewOption]="false"
    (modelsFiltered)="onFilterChanged($event)"
  >
  </app-model-filter>
</div>
<div class="list">
  <app-model-item
    *ngFor="let model of filteredModels$ | async"
    [model]="model"
    [selectable]="true"
    (selected)="onModelSelected($event)"
  ></app-model-item>
</div>
