import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesService } from 'src/app/services/http/devices.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';

import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { SiteTag } from 'src/models/device.models';

@UntilDestroy()
@Component({
  selector: 'app-gateway-import',
  templateUrl: './gateway-import.component.html',
  styleUrls: ['./gateway-import.component.scss'],
})
export class GatewayImportComponent {
  gatewayForm = this.buildForm();
  sites$: Observable<SiteTag[]>;

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private device: DevicesService,
    private router: Router,
    public auth: AuthService,
  ) {
    this._loadData();
    this.sites$ = this.data.sites$.pipe(
      filter((sites) => !!sites),
      map((sites) => {
        return this.auth.isAdmin() || this.auth.isTechnicalAdmin()
          ? sites!
          : sites!.filter((site) => this.auth.isMaintainerOnSite(site.tag_id));
      }),
    );
  }

  private _loadData() {
    this.dataLoader.loadSites().subscribe();
  }

  private buildForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      gateway_id: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('MULTITECH_LORA', Validators.required),
      name: new UntypedFormControl(''),
      site: new UntypedFormControl('', Validators.required),
    });
  }

  public submitForm(): void {
    this.device
      .importGateway(this.gatewayForm.value)
      .pipe(
        tap(() => this.router.navigate(['home', 'gateways'])),
        switchMap(() => this.dataLoader.loadGateways()),
      )
      .subscribe();
  }
}
