import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceTypeDto } from 'src/models/device-type.models';
import {
  DeviceDto,
  fireflyDeviceCSVInfo,
  iotCoreDeviceCSVInfo,
} from 'src/models/device.models';
import { DeviceCreationService } from '../device-creation/device-creation.service';

@Component({
  selector: 'app-device-providing',
  templateUrl: './device-providing.component.html',
  styleUrls: ['./device-providing.component.scss'],
})
export class DeviceProvidingComponent {
  creationRequestLoader = false;
  createdDevices: DeviceDto[] = [];
  errorResult: string;
  path = this.route.snapshot.queryParams.path;

  regions = this.data.regions;
  dataRates = this.data.dataRates;

  private _csvTemplates = {
    firefly: createFirstLinesOfCsvModels(fireflyDeviceCSVInfo),
    iotcore: createFirstLinesOfCsvModels(iotCoreDeviceCSVInfo),
  };

  private template = this._csvTemplates.firefly;
  defaultRegion = this.regions[0].value;
  defaultDatarate = this.dataRates[0]?.value;

  deviceTypeControl = new UntypedFormControl(null, [Validators.required]);

  httpPayload: string;
  deviceCount: number;

  constructor(
    private route: ActivatedRoute,
    private deviceCreationService: DeviceCreationService,
    private data: DataStoreService,
    public ref: MatDialogRef<unknown>,
  ) {}

  downloadTemplate(template = this.template, deviceType = 'firefly') {
    const blob = new Blob([template], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = deviceType + '_device_import.csv';
    link.click();
    URL.revokeObjectURL(url);
  }

  downloadErrorResults(errorResult: string, deviceType = 'firefly') {
    const blob = new Blob([errorResult], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = deviceType + '_device_creation_failures.csv';
    link.click();
    URL.revokeObjectURL(url);
  }

  uploadFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';

    fileInput.onchange = () => {
      const file = fileInput.files?.item(0);

      if (!file) return;

      const reader = new FileReader();

      reader.onloadend = () => {
        const content = reader.result as string;
        const lines = content.split('\r\n');
        const delimiter = detectDelimiter(lines[0]);

        lines[0] += `${delimiter}activationType${delimiter}isClassC${delimiter}dataRate`;
        lines[1] += `${delimiter}Activation Type 'OTAA'${delimiter}Class C device${delimiter}Device data rate`;

        lines
          .slice(2)
          .forEach(
            (line, i) =>
              (lines[i + 2] += line
                ? `${delimiter}OTAA${delimiter}false${delimiter}${this.defaultDatarate}`
                : ''),
          );

        this.httpPayload = lines.join('\n');
        this.deviceCount = lines.slice(2).filter((v) => !!v).length;
      };

      reader.readAsText(file);
    };

    fileInput.click();
  }

  uploadDevices() {
    this.creationRequestLoader = true;
    this.deviceCreationService
      .createFireflyDevices(
        this.httpPayload,
        this.path,
        (this.deviceTypeControl.value as DeviceTypeDto).device_type_id,
      )
      .subscribe(
        (res) => {
          this.createdDevices = res.created;
        },
        (error) => {
          this.errorResult = error.error.error;
          this.createdDevices = error.error.created;
        },
      );
  }

  get failureCount() {
    return this.deviceCount - this.createdDevices.length;
  }
}

function createFirstLinesOfCsvModels(objectToUse: Record<string, string>) {
  return Object.entries(objectToUse)
    .reduce(
      ([fl, sl], [key, value]) => [
        fl ? `${fl},${key}` : `${key}`,
        sl ? `${sl},${value}` : `${value}`,
      ],
      ['', ''],
    )
    .join('\n');
}

function detectDelimiter(input: string): string {
  const separators = [',', ';', '|', '\t'];
  const idx = separators
    .map((separator) => input.indexOf(separator))
    .reduce((prev, cur) =>
      prev === -1 || (cur !== -1 && cur < prev) ? cur : prev,
    );
  return (input[idx] || ',') as string;
}
