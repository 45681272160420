import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { DeviceDto } from 'src/models/device.models';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class DeviceListComponent implements AfterViewInit {
  @Input() set devices(dev: DeviceDto[]) {
    this.dataSource = new MatTableDataSource<DeviceDto>(dev);
    this.initializeSort();
  }
  public datas: { deviceId: string }[] = [];
  public screensizeIndex$ = this.interfaceState.breakpointIndex$;
  public dataSource = new MatTableDataSource<DeviceDto>();
  public displayedColumns: string[] = [
    'status',
    'site',
    'name',
    'last_uplink',
    'battery',
    'routings',
    'info',
  ];

  constructor(
    private router: Router,
    private interfaceState: InterfaceStateService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.initializeSort();
  }

  private siteLongName(item: DeviceDto) {
    return `${item.site?.metadata.iso_code} - ${item.site?.metadata.site_long_name}`;
  }

  public onShowDetails(device: DeviceDto): void {
    this.router.navigateByUrl(`/home/device/details/${device.device_id}`);
  }

  public onShowRoutings(device: DeviceDto): void {
    this.interfaceState.setRoutingFilter(
      this.interfaceState.buildRoutingFilter(device.device_id),
    );
    this.router.navigateByUrl(`/home/routing`);
  }

  public initializeSort() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'site':
          return this.siteLongName(item).toLowerCase();
        case 'last_uplink':
          return item.statistics?.last_uplink
            ? new Date(item.statistics.last_uplink).getTime()
            : undefined;
        case 'name':
          return item.metadata?.name.toLowerCase();
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }
}
