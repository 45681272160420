import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { InterfaceManagementModule } from 'src/app/pages/main/interface-management/interface-management.module';
import { SharedModule } from 'src/app/services/shared.module';
import { AlertingModule } from './alerting/alerting.module';
import { CatalogueModule } from './catalogue/catalogue.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DeviceManagementModule } from './device-management/device-management.module';
import { GatewayModule } from './gateway-list/gateway.module';
import { MainComponent } from './main.component';
import { RoutingModule } from './routing-list/routing.module';
import { SiteModule } from './site/site.module';
import { UserManagementModule } from './user-management/user-management.module';

const COMPONENTS = [MainComponent];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    BrowserModule,
    RouterModule,
    SharedModule,
    DashboardModule,
    CatalogueModule,
    RoutingModule,
    GatewayModule,
    DeviceManagementModule,
    UserManagementModule,
    AlertingModule,
    HttpClientModule,
    SiteModule,
    InterfaceManagementModule,
  ],
})
export class MainModule {}
