import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LatLng } from 'leaflet';

export abstract class DeviceCreation {
  public form: UntypedFormGroup;
  public nestedFormGroups = {
    general: 'general',
    technical: 'technical',
    position: 'position',
    metadata: 'metaData',
  };

  public get generalForm(): UntypedFormGroup {
    return this.form.get(this.nestedFormGroups.general) as UntypedFormGroup;
  }

  public get technicalForm(): UntypedFormGroup {
    return this.form.get(this.nestedFormGroups.technical) as UntypedFormGroup;
  }

  public get positionForm(): UntypedFormGroup {
    return this.form.get(this.nestedFormGroups.position) as UntypedFormGroup;
  }

  public get metadataForm(): UntypedFormGroup {
    return this.form.get(this.nestedFormGroups.metadata) as UntypedFormGroup;
  }

  public get descriptionFormControl(): UntypedFormControl {
    return this.form.get('description') as UntypedFormControl;
  }

  constructor() {}

  public onSetPosition(latLng: LatLng) {
    this.positionForm.controls.lat.setValue(latLng.lat);
    this.positionForm.controls.lng.setValue(latLng.lng);
  }

  public onMetadataChange(form: UntypedFormGroup): void {
    this.form.controls.metaData = form;
    this.form.updateValueAndValidity();
  }
}
