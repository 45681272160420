<ng-container *appLoader="device">
  <header class="header">
    <h2 class="title">{{ device?.metadata?.name }}</h2>
    <div class="spacer"></div>
    <app-counter-chip
      *ngIf="messageKeys.length"
      [label]="'Total Data'"
      [count]="messageKeys.length"
    ></app-counter-chip>
    <div class="vertical-line"></div>
    <button
      mat-button
      (click)="onSendDownlink()"
      *ngIf="!actionsHidden && canSendDownlink()"
      class="blue-btn"
      matTooltipPosition="below"
      matTooltip="send a command to this device"
    >
      Send Downlink
    </button>
    <div class="absolute">
      <div
        class="function-marker"
        *ngIf="!!device?.calibration_functions?.length"
      >
        <p class="func-count">
          {{
            device.calibration_functions.length <= 9
              ? device.calibration_functions.length
              : '9+'
          }}
        </p>
      </div>
      <button
        mat-icon-button
        (click)="onFunction()"
        *ngIf="!actionsHidden"
        class="big"
        matTooltipPosition="below"
        matTooltip="update device characterization"
      >
        <mat-icon>functions</mat-icon>
      </button>
    </div>

    <button
      mat-icon-button
      (click)="onEdit()"
      *ngIf="!actionsHidden"
      class="big"
      matTooltipPosition="below"
      matTooltip="edit device"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="!actionsHidden"
      (click)="onDelete(deleteDeviceTmpl)"
      class="big"
      matTooltipPosition="below"
      matTooltip="delete device"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onClose()"
      class="big"
      matTooltipPosition="below"
      matTooltip="close device details"
    >
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div
    class="device-image mat-elevation-z4"
    [ngStyle]="{ 'background-image': deviceImageUrl }"
  >
    <div class="btn-container">
      <button
        mat-icon-button
        (click)="
          onEditPicture(
            device.metadata.device_logo_url ||
              device.device_type.metadata.device_logo_url ||
              './assets/img/no_image_available.png'
          )
        "
        class="upload-image-btn"
        matTooltipPosition="below"
        matTooltip="upload device image"
      >
        <mat-icon>add_a_photo</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="onEnlargePicture(enlargedImageTpl)"
        class="enlarge-image-btn"
        matTooltipPosition="below"
        matTooltip="enlarge image"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  </div>
  <section class="detail mat-elevation-z4">
    <app-detail-stepper [device]="device"></app-detail-stepper>
  </section>
  <section class="map mat-elevation-z4">
    <app-display-position-map [devices]="deviceList"></app-display-position-map>
  </section>
  <section class="data-preview">
    <div class="data" *appLoader="messages && messageKeys">
      <mat-paginator
        class="sticky"
        *ngIf="messages.length"
        [pageSize]="pageSize"
        (page)="updateMessages($event)"
        [length]="deviceMessagesCount"
        [disabled]="messagesLoading"
      ></mat-paginator>
      <app-data-preview
        [columnsToDisplay]="messageKeys"
        [tableData]="messages"
        (rowSelect)="selectedRow = $event"
      ></app-data-preview>
    </div>
    <div class="json mat-elevation-z4" *ngIf="selectedRow" @json>
      <pre><code>{{ selectedRow | json }}</code></pre>
    </div>
  </section>
  <section class="warnings-box">
    <div
      *ngFor="let warning of openWarnings; let i = index"
      class="warning-flag"
    >
      <p>
        {{ warning }}
      </p>
      <button mat-stroked-button (click)="closeWarning(i)">Understood</button>
    </div>
  </section>

  <ng-template #deleteDeviceTmpl>
    <h1 mat-dialog-title>Attention!</h1>
    <div mat-dialog-content>
      <p>This action will delete the device permanently!</p>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-button [mat-dialog-close]="false" class="dialog-btn">
        Cancel
      </button>
      <button
        mat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
        class="dialog-btn"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #enlargedImageTpl>
    <div
      class="large-image"
      [ngStyle]="{ 'background-image': deviceImageUrl }"
    ></div>

    <div mat-dialog-actions class="dialog-actions">
      <button mat-button [mat-dialog-close]="false" class="dialog-btn">
        Close
      </button>
    </div>
  </ng-template>
</ng-container>
