<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title> Decoding configuration test </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="form" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Payload</mat-label>
      <input matInput formControlName="payload" />
      <mat-error *ngIf="form.controls.payload.hasError('pattern')">
        Test payload must be a hexadecimal string!
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Port</mat-label>
      <input matInput formControlName="port" type="number" />
      <mat-error *ngIf="form.controls.port.hasError('pattern')">
        Port must be a number!
      </mat-error>
    </mat-form-field>
    <button
      mat-button
      color="primary"
      (click)="testBlueprint()"
      [disabled]="form.invalid"
    >
      Test
    </button>
  </div>
  <div class="response" *ngIf="decodedPayload">
    <pre><code>{{ resultPreview | json }}</code></pre>
  </div>
</mat-expansion-panel>
