<div class="wrapper">
  <input
    type="file"
    name="{{ id }}"
    id="{{ id }}"
    accept="image/*"
    class="inputfile"
    (change)="selectionChanged($event)"
  />
  <label for="{{ id }}" class="mat-elevation-z3">
    <mat-icon *ngIf="!imgPreviewUrl">add</mat-icon>
    <img
      [src]="imgPreviewUrl"
      alt="Preview"
      class="preview"
      *ngIf="imgPreviewUrl"
    />
  </label>
</div>
