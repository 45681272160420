import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { RoutingCreationService } from './routing-creation/routing-creation.service';

import { SharedModule } from 'src/app/services/shared.module';

import { DataSelectListComponent } from './routing-creation/destination-form/data-select-list/data-select-list.component';
import { DestinationFormComponent } from './routing-creation/destination-form/destination-form.component';
import { SheetDataBuilderComponent } from './routing-creation/destination-form/sheet-data-builder/sheet-data-builder.component';
import { MQTTTopicHelpDialogComponent } from './routing-creation/mqtt-topic-help-dialog/mqtt-topic-help-dialog.component';
import { PatternBuilderHelpComponent } from './routing-creation/pattern-builder-help/pattern-builder-help.component';
import { PatternBuilderComponent } from './routing-creation/pattern-builder/pattern-builder.component';
import { RoutingCreationComponent } from './routing-creation/routing-creation.component';
import { DataListComponent } from './routing-details/data-list/data-list.component';
import { DeviceDataSelectionComponent } from './routing-details/device-data-selection/device-data-selection.component';
import { RoutingDetailsComponent } from './routing-details/routing-details.component';
import { RoutingFilterComponent } from './routing-filter/routing-filter.component';
import { RoutingListElementComponent } from './routing-list-element/routing-list-element.component';
import { RoutingListComponent } from './routing-list.component';

const COMPONENTS = [
  RoutingListComponent,
  RoutingListElementComponent,
  RoutingFilterComponent,
  RoutingDetailsComponent,
  DeviceDataSelectionComponent,
  DataListComponent,
  RoutingCreationComponent,
  PatternBuilderComponent,
  PatternBuilderHelpComponent,
  MQTTTopicHelpDialogComponent,
  DestinationFormComponent,
  DataSelectListComponent,
  SheetDataBuilderComponent,
];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [COMPONENTS],
  providers: [RoutingCreationService],
  exports: [COMPONENTS],
})
export class RoutingModule {}
