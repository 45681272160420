<div class="list-management" *ngIf="auth.isAdmin()">
  <section class="filter-container mat-elevation-z4" *appLoader="interfaces$">
    <div *ngIf="(screensizeIndex$ | async) >= 2">
      <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    </div>
    <mat-expansion-panel
      *ngIf="(screensizeIndex$ | async) < 2"
      class="mat-elevation-z0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Filter </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    </mat-expansion-panel>
  </section>
  <section class="list" *appLoader="interfaces$">
    <div class="list-header mat-elevation-z4">
      <div class="list-row">
        <div class="list-col clip-text">Name</div>
        <div class="list-col clip-text">Sites</div>
        <div class="list-col">Destination</div>
        <div class="list-col">Topic id</div>
      </div>
    </div>
    <div class="list-body">
      <div
        class="list-row blue-bg clickable"
        *ngFor="let interf of filteredInterfaces$ | async"
        (click)="editInterface(interf)"
      >
        <div class="list-col clip-text">{{ interf.metadata.name }}</div>
        <div class="list-col clip-text">
          <div *ngFor="let site of interf.tags">
            {{ getSiteName(site) }}
          </div>
        </div>
        <div class="list-col">
          {{ getDestinationName(interf.metadata.destination) }}
        </div>
        <div class="list-col">{{ interf.topic }}</div>
      </div>
    </div>
  </section>
  <button
    mat-fab
    color="primary"
    class="floating-button"
    *ngIf="auth.isAdmin()"
    matTooltipPosition="below"
    matTooltip="add a new interface"
    (click)="createInterface()"
  >
    <mat-icon>add</mat-icon>
  </button>

  <ng-template #formTpl>
    <app-interface-filter
      [interfaces]="interfaces$ | async"
      (interfacesFiltered)="updateFilteredInterfaces($event)"
    ></app-interface-filter>
  </ng-template>
</div>
