<div class="card mat-elevation-z4">
  <div
    class="drag-checkbox"
    matTooltip="Check to add this device to your next dragging operation"
  >
    <mat-checkbox
      #dragBoxEl
      (change)="dragboxChange($event.checked)"
      (click)="$event.stopPropagation()"
      color="primary"
    ></mat-checkbox>
  </div>
  <div class="card-header">
    <img
      src="{{
        device?.metadata?.device_logo_url ||
          device.device_type?.metadata?.device_logo_url ||
          './assets/img/no_image_available.png'
      }}"
      class="mat-elevation-z2"
      alt="Device photo"
    />
    <p>
      {{
        device.metadata.name.length > 22
          ? (device.metadata.name | slice: 0 : 22) + '...'
          : device.metadata.name
      }}
    </p>
    <p>
      EUI:
      {{
        device.device_id.length > 16
          ? (device.device_id | slice: 0 : 16) + '...'
          : device.device_id
      }}
    </p>
  </div>
  <div class="card-body">
    <p class="label grid-case">Source</p>
    <div class="source grid-case">
      <p class="info">{{ device?.device_type?.source?.metadata?.label }}</p>
      <img
        src="{{ device?.device_type?.source?.metadata?.logo_url }}"
        alt="Data source logo"
        class="source-logo"
      />
    </div>
    <p class="label grid-case">Model</p>
    <p class="info grid-case">
      {{
        device?.device_type?.metadata.label?.length > 18
          ? (device?.device_type?.metadata?.label | slice: 0 : 18) + '...'
          : device?.device_type?.metadata?.label
      }}
    </p>
  </div>
</div>
