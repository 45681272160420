import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GatewayFilterComponent } from './gateway-filter/gateway-filter.component';
import { GatewayImportComponent } from './gateway-import/gateway-import.component';

import { SharedModule } from 'src/app/services/shared.module';
import { GatewayDataVisualComponent } from './gateway-data-visual/gateway-data-visual.component';
import { GatewayListComponent } from './gateway-list.component';
import { GatewayMapComponent } from './gateway-map/gateway-map.component';

const COMPONENTS = [
  GatewayListComponent,
  GatewayFilterComponent,
  GatewayDataVisualComponent,
  GatewayMapComponent,
  GatewayImportComponent,
];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [COMPONENTS],
  providers: [],
  exports: [COMPONENTS],
})
export class GatewayModule {}
