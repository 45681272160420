import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/app/services/shared.module';

import { BrandCreationComponent } from 'src/app/pages/main/catalogue/brand-creation/brand-creation.component';
import { BlueprintTestComponent } from 'src/app/pages/main/catalogue/model-detail/parser-tab/blueprint-test/blueprint-test.component';
import { BrandItemComponent } from './brand-item/brand-item.component';
import { CatalogueComponent } from './catalogue.component';
import { ModelCreationComponent } from './model-creation/model-creation.component';
import { ModelDetailComponent } from './model-detail/model-detail.component';
import { FieldHintComponent } from './model-detail/parser-tab/json-parser-editor/field-hint/field-hint.component';
import { JsonInputComponent } from './model-detail/parser-tab/json-parser-editor/json-input/json-input.component';
import { JsonParserEditorComponent } from './model-detail/parser-tab/json-parser-editor/json-parser-editor.component';
import { MessageFieldMappingComponent } from './model-detail/parser-tab/json-parser-editor/message-field-mapping/message-field-mapping.component';
import { MultiParserEditorComponent } from './model-detail/parser-tab/multi-parser-editor/multi-parser-editor.component';
import { SingleParserDialogComponent } from './model-detail/parser-tab/multi-parser-editor/single-parser-dialog/single-parser-dialog.component';
import { ParserTabComponent } from './model-detail/parser-tab/parser-tab.component';
import { ParserTypeSelectionComponent } from './model-detail/parser-tab/parser-type-selection/parser-type-selection.component';
import { SingleParserEditorComponent } from './model-detail/parser-tab/single-parser-editor/single-parser-editor.component';

const COMPONENTS = [
  CatalogueComponent,
  BrandItemComponent,
  ModelDetailComponent,
  ParserTabComponent,
  ParserTypeSelectionComponent,
  SingleParserEditorComponent,
  ModelCreationComponent,
  MultiParserEditorComponent,
  SingleParserDialogComponent,
  JsonParserEditorComponent,
  JsonInputComponent,
  MessageFieldMappingComponent,
  FieldHintComponent,
  BlueprintTestComponent,
  BrandCreationComponent,
];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class CatalogueModule {}
